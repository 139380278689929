import { useMutation } from '@tanstack/react-query';
import publicApiRequest from '../../../services/publicApiWrapper';
import { PARENTS_URL } from '../../../utils/constants/urlConstants';

async function completeParentRegistration(familyId: string | null) {
  return publicApiRequest.post(
    `${PARENTS_URL}/invite/complete-registration?familyId=${familyId}`,
    {}
  );
}
export function useCompleteRegistrationForBothParents(familyId: string | null) {
  return useMutation({
    mutationFn: () => completeParentRegistration(familyId),
  });
}
