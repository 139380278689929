import { Button, Checkbox, Table, Tag } from '@ph-react-ui/core';
import { format } from 'date-fns';
import { useMemo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';
import { useApproveStudentById } from '../../hooks/users/students/useApproveStudentById';
import { usePaginatedUsers } from '../../hooks/users/usePaginatedUsers';
import { type SelectAll, useSelectAll } from '../../hooks/useSelectAll';
import type { UsersDto } from '../../models/users/users.dto';
import {
  ADD_TO_GROUP,
  ARCHIVE_USERS,
  ARCHIVE_USER,
  CREATE_EDIT_USER,
  UNARCHIVE_USER,
} from '../../utils/constants/users/modals';
import { ROLE } from '../../utils/constants/users/role';
import { STATUS } from '../../utils/constants/users/status';
import { ModalType } from '../../utils/enums/modal-type.enum';
import { Role } from '../../utils/enums/role.enum';
import { Status } from '../../utils/enums/status.enum';
import { UserOrdering } from '../../utils/enums/user-ordering.enum';
import { getTagType } from '../../utils/getTagType';
import { ActionMenuHorizontal } from '../shared/components/ActionMenuHorizontal';
import { Pagination } from '../shared/components/Pagination';
import { FilterSkeleton } from '../shared/components/skeletons/FilterSkeleton';
import { TableSkeleton } from '../shared/components/skeletons/TableSkeleton';
import { ThSortItem } from '../shared/components/tables/ThSortItem';
import { UserFilter } from './filter/UserFilter';

export function Users() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { open: openCreateEditUserModal } = useModal(CREATE_EDIT_USER);
  const { open: openArchiveUserModal } = useModal(ARCHIVE_USER);
  const { open: openUnarchiveUserModal } = useModal(UNARCHIVE_USER);
  const { open: openGroupModal } = useModal(ADD_TO_GROUP);
  const { open: openArchiveMultipleModal } = useModal(ARCHIVE_USERS);

  const { data: users, isSuccess, isPending } = usePaginatedUsers(searchParams);

  const { control, handleSubmit, handleSelectAll, selectAll, dirtyFields } =
    useSelectAll(users?.result);

  const headers = [
    <div className="users__table__select-all">
      <Checkbox
        onChange={(checked) => handleSelectAll(checked)}
        value={selectAll}
      />
      <ThSortItem
        title={t('USERS.TABLE.HEADERS.NAME')}
        ordering={UserOrdering.Name}
      />
    </div>,
    t('USERS.TABLE.HEADERS.ROLE'),
    t('USERS.TABLE.HEADERS.STATUS'),
    <ThSortItem
      title={t('USERS.TABLE.HEADERS.DATE_OF_REGISTRATION')}
      ordering={UserOrdering.CreatedAt}
    />,
    t('USERS.TABLE.HEADERS.ACTION'),
  ];

  const { mutate: approveStudentById } = useApproveStudentById();

  // TODO: handle this for parents
  const onOpenUserDetailsPage = useCallback(
    (user: UsersDto) => {
      openCreateEditUserModal({
        userId: user.id,
        userRole: user.role.toString(),
        userStatus: user.status.toString(),
      });
    },
    [openCreateEditUserModal]
  );

  const onOpenArchiveModal = useCallback(
    (user: UsersDto) => {
      openArchiveUserModal({
        userId: user.id,
        userRole: user.role.toString(),
      });
    },
    [openArchiveUserModal]
  );

  const onOpenUnarchiveModal = useCallback(
    (user: UsersDto) => {
      openUnarchiveUserModal({
        userId: user.id,
        userRole: user.role.toString(),
      });
    },
    [openUnarchiveUserModal]
  );

  const onOpenGroupsModal = useCallback(
    (user: UsersDto) => {
      openGroupModal({
        userId: user.id,
      });
    },
    [openGroupModal]
  );

  const openCreateUserDrawer = () => {
    openCreateEditUserModal();
  };

  const tableChildren = useMemo(() => {
    return users?.result.reduce((acc: any, user, index) => {
      let userArray = [
        <div className="users__table__checkbox">
          <Controller
            name={`items.${index}.value`}
            control={control}
            render={({ field }) => (
              <Checkbox className="users__table-first" {...field} />
            )}
          />
          {user.name}
        </div>,
        t(`USERS.TABLE.ROLE.${ROLE[user.role]}`),
        <Tag type={getTagType(user.status)}>
          {t(`USERS.TABLE.STATUS.${STATUS[user.status]}`)}
        </Tag>,
        format(new Date(user.createdAt), 'dd.MM.yyyy'),
        <ActionMenuHorizontal
          options={[
            {
              label: t('ACTIONS.EDIT'),
              onClick: () => onOpenUserDetailsPage(user),
            },
            {
              label: t('ACTIONS.ADD_TO_GROUP'),
              onClick: () => onOpenGroupsModal(user),
            },
            {
              label: t('ACTIONS.ARCHIVE'),
              onClick: () => onOpenArchiveModal(user),
              hidden: user.status === Status.Archived,
            },
            {
              label: t('ACTIONS.UNARCHIVE'),
              onClick: () => onOpenUnarchiveModal(user),
              hidden: user.status !== Status.Archived,
            },
            {
              label: t('ACTIONS.APPROVE'),
              onClick: () => approveStudentById(user.id),
              hidden: user.status !== Status.New || user.role !== Role.Student,
            },
          ]}
        />,
      ];

      return [...acc, userArray];
    }, []);
  }, [
    users,
    t,
    control,
    onOpenUserDetailsPage,
    onOpenGroupsModal,
    onOpenArchiveModal,
    onOpenUnarchiveModal,
    approveStudentById,
  ]);

  const onSubmit = (data: SelectAll, modal: ModalType) => {
    const selectedUsers = data.items.filter((user) => user.value);
    const ids = selectedUsers.map((item) => item.id).join('&');
    switch (modal) {
      case ModalType.Archive:
        return openArchiveMultipleModal({ userId: ids });
      case ModalType.AddToGroup:
        return openGroupModal({ userId: ids });
      default:
    }
  };

  const handleModalType = (modal: ModalType) => {
    handleSubmit((data) => onSubmit(data, modal))();
  };

  return (
    <div className="users">
      <section className="users__actions">
        <Button compact variant="outlined" onClick={openCreateUserDrawer}>
          {t('USERS.ACTIONS.ADD_A_USER')}
        </Button>
      </section>
      <form>
        {isPending ? (
          <FilterSkeleton />
        ) : (
          <>
            <UserFilter
              handleModalType={handleModalType}
              isDisabled={!dirtyFields.items?.some((item) => item.value)}
            />
          </>
        )}
        {isSuccess && users ? (
          <>
            <Table headers={headers} rows={tableChildren} />
            <div className="users__pagination">
              <Pagination data={users} />
            </div>
          </>
        ) : (
          <TableSkeleton headers={headers} />
        )}
      </form>
    </div>
  );
}
