import { Button, useNotifications } from '@ph-react-ui/core';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, Link, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth/useAuth';
import { useSignIn } from '../../../hooks/auth/useSignIn';
import type { UserCredentialsDto } from '../../../models/auth/login.dto';
import { Input } from '../../../utils/components/hoc-components';
import { Role } from '../../../utils/enums/role.enum';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../utils/inputValidators';

export function Login() {
  const { setAuthInfo, isAuthenticated, authState } = useAuth();
  const [loginAttempts, setLoginAttempts] = useState(1);
  const [searchParams] = useSearchParams();
  const notification = useNotifications();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const continueToUrl = searchParams.get('continueTo');

  const methods = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const signIn = useSignIn({
    onError: () => {
      methods.resetField('password');
      setLoginAttempts(loginAttempts + 1);
    },
  });

  useEffect(() => {
    if (loginAttempts > 3) {
      navigate('/auth/forgot-password');
    }
  }, [loginAttempts, navigate]);

  const onSubmit = (data: UserCredentialsDto) => {
    signIn
      .mutateAsync({
        email: data.email,
        password: data.password,
      })
      .then((response) => {
        setAuthInfo({
          accessToken: response.accessToken,
          expires: response.expires,
          refreshToken: response.refreshToken,
          role: response.role,
          userId: response.userId,
        });
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const goToFirstPage = () => {
    if (authState?.role === Role.Admin) {
      return '/dashboard/users';
    }
    return '/dashboard/profile';
  };

  return isAuthenticated() ? (
    <Navigate
      to={continueToUrl ? continueToUrl.slice(1, -1) : goToFirstPage()}
    />
  ) : (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <h2 className="auth__window__title">{t('AUTH.TITLE.LOGIN')}</h2>
        <div className="auth__window__inputs">
          <Input
            rules={getCommonValidators([V.REQUIRED, V.EMAIL_PATTERN])}
            name="email"
            placeholder={t('AUTH.EMAIL') ?? ''}
            label={`${t('AUTH.EMAIL')}`}
          />
          <Input
            rules={getCommonValidators([V.REQUIRED])}
            name="password"
            inputType="password"
            label={`${t('AUTH.PASSWORD')}`}
            placeholder={t('AUTH.PASSWORD') ?? ''}
          />
        </div>
        <Button
          type="submit"
          loading={signIn.isPending}
          className="auth__window__button"
          color="success"
        >
          {t('AUTH.ACTIONS.LOGIN')}
        </Button>
        <div className="auth__window__actions">
          <Link to="/auth/forgot-password">
            {t('AUTH.ACTIONS.FORGOT_PASSWORD')}
          </Link>
          <Link to="/admission/application">{t('AUTH.ACTIONS.REGISTER')}</Link>
        </div>
      </form>
    </FormProvider>
  );
}
