import { Table, Tag, Tooltip, useNotifications } from '@ph-react-ui/core';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../../../hooks/auth/useAuth';
import { useDownloadFile } from '../../../../../hooks/files/useDownloadFile';
import { useGeneratePdfReceipt } from '../../../../../hooks/payments/useGeneratePdfReceipt';
import { usePaginatedHistoryOfSchoolTaxPayments } from '../../../../../hooks/taxes/school-taxes/parent/history/usePaginatedHistoryOfSchoolTaxPayments';
import { PaymentType } from '../../../../../utils/enums/payment-type.enum';
import { ScopeAccess } from '../../../../../utils/enums/scope-access.enum';
import { ActionMenuHorizontal } from '../../../../shared/components/ActionMenuHorizontal';
import { Pagination } from '../../../../shared/components/Pagination';
import { TableSkeleton } from '../../../../shared/components/skeletons/TableSkeleton';

export function SchoolTaxesHistory() {
  const { authState } = useAuth();
  const canMakePayments = authState?.scope?.includes(ScopeAccess.MakePayments);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const notification = useNotifications();

  const [searchParams] = useSearchParams();
  const { data, isSuccess, isPending } =
    usePaginatedHistoryOfSchoolTaxPayments(searchParams);

  const headers = [
    t('TAXES.TABLE.HEADERS.STUDENT'),
    t('TAXES.TABLE.HEADERS.AMOUNT'),
    t('TAXES.TABLE.HEADERS.STATUS'),
    t('TAXES.TABLE.HEADERS.PAYMENT_PERIOD'),
    t('TAXES.TABLE.HEADERS.PAYMENT_DATE'),
    t('TAXES.TABLE.HEADERS.PAID_WITH'),
    t('TAXES.TABLE.HEADERS.NOTES'),
  ];
  if (canMakePayments) {
    headers.push(t('TAXES.TABLE.HEADERS.ACTIONS'));
  }

  const onPayNow = useCallback(
    (id: string, studentId: string) => {
      navigate(
        `/dashboard/payments/school-taxes/pre-payment/${id}/${studentId}?withFallback=true`
      );
    },
    [navigate]
  );

  const downloadFile = useDownloadFile();
  const generateReceipt = useGeneratePdfReceipt();

  const onDownloadReceipt = useCallback(
    (id: string, fileId: string | null) => {
      if (fileId) {
        downloadFile
          .mutateAsync(fileId)
          .then((res) => window.open(res.uri))
          .catch((resError) => {
            notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
          });
      } else {
        generateReceipt
          .mutateAsync({ id, type: PaymentType.SchoolFee })
          .then((receiptId) => {
            if (receiptId) {
              downloadFile
                .mutateAsync(receiptId as string)
                .then((res) => window.open(res.uri))
                .catch((resError) => {
                  notification.danger(
                    t(`NETWORK_ERRORS.${resError.errors[0]}`)
                  );
                });
            }
          })
          .catch((resError) => {
            notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
          });
      }
    },
    [downloadFile, generateReceipt, notification, t]
  );

  const tableChildren = useMemo(() => {
    return data?.result.reduce((acc: any, user) => {
      let userArray = [
        user.name,
        <span>
          {user.amount.toFixed(2)}&nbsp;{t('TAXES.LABELS.BGN')}
        </span>,
        user.isPaid ? (
          <Tag type="success">{t('TAXES.STATUS.PAID')}</Tag>
        ) : (
          <Tag type="accent">{t('TAXES.STATUS.PENDING')}</Tag>
        ),
        user.fromDate
          ? `${t(
              `MONTHS.${format(new Date(user.fromDate), 'MMMM').toUpperCase()}`
            )} ${new Date(user.fromDate).getFullYear()}`
          : '-',
        user.isPaid && user.paidAt
          ? format(new Date(user.paidAt), 'dd.MM.yyyy')
          : '',
        user.isPaid
          ? user.isPaidWithCard
            ? t('TAXES.LABELS.CARD')
            : t('TAXES.LABELS.TRANSFER')
          : '',

        user.note ? (
          <span className="taxes__school__table__row__info">
            <Tooltip
              className="taxes__school__table__row__tooltip"
              message={user.note}
            >
              {user.note.slice(0, 25) + '...'}
            </Tooltip>
          </span>
        ) : (
          ''
        ),
      ];
      if (canMakePayments) {
        userArray.push(
          !user.isPaid ? (
            <ActionMenuHorizontal
              options={[
                {
                  label: t('ACTIONS.PAY_NOW'),
                  onClick: () => onPayNow(user.id, user.userId),
                },
              ]}
            />
          ) : user.isPaidWithCard ? (
            <ActionMenuHorizontal
              options={[
                {
                  label: t('ACTIONS.DOWNLOAD_RECEIPT'),
                  onClick: () =>
                    onDownloadReceipt(user.id, user.fileMetadataId),
                },
              ]}
            />
          ) : (
            ''
          )
        );
      }

      return [...acc, userArray];
    }, []);
  }, [data?.result, t, onPayNow, onDownloadReceipt, canMakePayments]);
  return (
    <div className="taxes__school">
      <h2 className="taxes__school__title">
        {t('TAXES.TITLES.SCHOOL_TAXES_HISTORY')}
      </h2>
      {isPending && <TableSkeleton />}
      {isSuccess && data ? (
        <>
          <Table headers={headers} rows={tableChildren} />
          <div className="users__pagination">
            <Pagination data={data} />
          </div>
        </>
      ) : (
        <TableSkeleton />
      )}
    </div>
  );
}
