import { Button } from '@ph-react-ui/core';
import { useTranslation } from 'react-i18next';
import { ModalType } from '../../../utils/enums/modal-type.enum';

interface GroupFilterProps {
  handleModalType: (modal: ModalType) => void;
  isDisabled: boolean;
}

export const GroupFilter = ({
  handleModalType,
  isDisabled,
}: GroupFilterProps) => {
  const { t } = useTranslation();

  return (
    <div className="pt-2 pb-1">
      <div className="groups__filters">
        <div className="groups__filters__actions">
          <Button
            type="button"
            onClick={() => handleModalType(ModalType.AddToGroup)}
            disabled={isDisabled}
          >
            {t('USERS.ACTIONS.ADD_TO_GROUP')}
          </Button>
          <Button
            type="button"
            disabled={isDisabled}
            onClick={() => handleModalType(ModalType.MoveToGroup)}
          >
            {t('USERS.ACTIONS.MOVE_TO_GROUP')}
          </Button>
          <Button
            type="button"
            disabled={isDisabled}
            onClick={() => handleModalType(ModalType.RemoveGroupMember)}
          >
            {t('ACTIONS.REMOVE')}
          </Button>
        </div>
      </div>
    </div>
  );
};
