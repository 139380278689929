import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../../../services/apiWrapper';
import { SCHOOL_TAX_PAYMENTS } from '../../../../utils/constants/urlConstants';

async function fetchExportSchoolTaxesReport(searchParams: URLSearchParams) {
  return await apiRequest.get(
    `${SCHOOL_TAX_PAYMENTS}/export?${searchParams.toString()}`,
    { responseType: 'blob' }
  );
}

export function useExportSchoolTaxesReport(searchParams: URLSearchParams) {
  return useMutation({
    mutationFn: () => fetchExportSchoolTaxesReport(searchParams),
  });
}
