import { DatePicker, type SimpleDate, Table } from '@ph-react-ui/core';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useSearchParams } from 'react-router-dom';
import { useExportStudentAbsencesReport } from '../../../hooks/users/students/useExportStudentAbsencesReport';
import { usePaginatedStudentAbsences } from '../../../hooks/users/students/usePaginatedStudentAbsences';
import { mapStringToSimpleDate } from '../../../services/mapStringToSimpleDate';
import { STUDENT_ABSENCE_REASON } from '../../../utils/constants/student-absence-reason';
import { StudentAbsenceOrdering } from '../../../utils/enums/student-absence-ordering.enum';
import ExportButton from '../../shared/components/ExportButton';
import { Pagination } from '../../shared/components/Pagination';
import { SearchInput } from '../../shared/components/SearchByNameInput';
import { TableSkeleton } from '../../shared/components/skeletons/TableSkeleton';
import { ThSortItem } from '../../shared/components/tables/ThSortItem';

export function StudentAbsences() {
  const { t } = useTranslation();
  const match = useMatch('/dashboard/student-absences');
  const [searchParams, setSearchParams] = useSearchParams();
  const fromDateParam = searchParams.get('fromDate');
  const toDateParam = searchParams.get('toDate');

  const { data: students, isSuccess } =
    usePaginatedStudentAbsences(searchParams);

  const exportReport = useExportStudentAbsencesReport(searchParams);

  const onSetDate = ({
    value,
    paramName,
  }: {
    value: SimpleDate | null;
    paramName: string;
  }) => {
    if (value) {
      const date = new Date(value.year, value.month, value.date);
      searchParams.set(paramName, format(date, 'yyyy-MM-dd'));
      searchParams.set('page', '1');
    } else {
      searchParams.delete(paramName);
    }
    setSearchParams(searchParams);
  };

  const headers = [
    <ThSortItem
      title={t('STUDENT_ABSENCES.TABLE.HEADERS.NAME')}
      ordering={StudentAbsenceOrdering.Name}
    />,
    <ThSortItem
      title={t('STUDENT_ABSENCES.TABLE.HEADERS.FROM_DATE')}
      ordering={StudentAbsenceOrdering.FromDate}
    />,
    <ThSortItem
      title={t('STUDENT_ABSENCES.TABLE.HEADERS.TO_DATE')}
      ordering={StudentAbsenceOrdering.ToDate}
    />,
    t('STUDENT_ABSENCES.TABLE.HEADERS.REASON'),
    t('STUDENT_ABSENCES.TABLE.HEADERS.NOTE'),
  ];

  const tableChildren = useMemo(() => {
    return students?.result.reduce((acc: any, student) => {
      let studentArray = [
        student.name,
        format(new Date(student.fromDate), 'dd/MM/yyyy'),
        format(new Date(student.toDate), 'dd/MM/yyyy'),
        t(
          `STUDENT_ABSENCES.TABLE.ABSENCE_REASON.${
            STUDENT_ABSENCE_REASON[student.absenceReason]
          }`
        ),
        student.note
          ? student.note.length < 100
            ? student.note
            : student.note.slice(0, 100) + '...'
          : '',
      ];
      return [...acc, studentArray];
    }, []);
  }, [students?.result, t]);

  return (
    <div className="student-absences">
      <section className="student-absences__actions">
        <ExportButton
          exportRequest={exportReport}
          successMessage={t('STUDENT_ABSENCES.MESSAGES.EXPORT_SUCCESS')}
        />
      </section>
      <div className="student-absences__table">
        <div className="student-absences__table__filters">
          <SearchInput
            pathMatch={match}
            placeholder={t('STUDENT_ABSENCES.TABLE.FILTERS.SEARCH_USER')}
          />
          <DatePicker
            value={mapStringToSimpleDate(fromDateParam)}
            onChange={(value) => onSetDate({ value, paramName: 'fromDate' })}
            compact
            placeholder={t('STUDENT_ABSENCES.TABLE.FILTERS.FROM_DATE') ?? ''}
          />
          <DatePicker
            value={mapStringToSimpleDate(toDateParam)}
            onChange={(value) => onSetDate({ value, paramName: 'toDate' })}
            compact
            placeholder={t('STUDENT_ABSENCES.TABLE.FILTERS.TO_DATE') ?? ''}
          />
        </div>
        {isSuccess && students ? (
          <>
            {students.totalCount > 0 ? (
              <Table headers={headers} rows={tableChildren} />
            ) : (
              t('STUDENT_ABSENCES.MESSAGES.NO_DATA')
            )}
            <div className="users__pagination">
              <Pagination data={students} />
            </div>
          </>
        ) : (
          <TableSkeleton headers={headers} />
        )}
      </div>
    </div>
  );
}
