import { Add } from '@carbon/icons-react';
import React from 'react';

interface AddBtnProps {
  btnLabel: string;
  onClick?: () => void;
}

const AddBtn = ({ btnLabel, onClick }: AddBtnProps) => {
  return (
    <div className="drawer__transportation-management__form__add-stop mt-4">
      <button
        type="button"
        className="drawer__transportation-management__form__add-stop__button"
        onClick={onClick}
      >
        <Add className="drawer__transportation-management__form__add-stop__button__icon" />
        <span className="ml-1">{btnLabel}</span>
      </button>
    </div>
  );
};

export default AddBtn;
