import { Button, useNotifications } from '@ph-react-ui/core';
import type { UseMutationResult } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';

const getFileNameFromHeader = (
  contentDisposition: string | undefined
): string | null => {
  let fileName = null;
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(
      /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    );
    if (fileNameMatch && fileNameMatch[1]) {
      fileName = fileNameMatch[1].replace(/['"]/g, '');
    }
  }
  return fileName;
};

interface ExportButtonProps {
  exportRequest: UseMutationResult<
    AxiosResponse<unknown, any>,
    Error,
    void,
    unknown
  >;
  successMessage: string;
  errorMessage?: string;
  fileName?: string;
  buttonLabel?: string;
  fileType?: string;
}

// This component is used to export reports in CSV format, it will try to get the file name from the response headers
const ExportButton: React.FC<ExportButtonProps> = ({
  exportRequest,
  successMessage,
  errorMessage,
  fileName = 'download.csv',
  buttonLabel,
  fileType = 'text/csv',
}) => {
  const notification = useNotifications();
  const { t } = useTranslation();

  if (!buttonLabel) {
    buttonLabel = t('ACTIONS.EXPORT').toString();
  }

  const handleExport = () => {
    exportRequest
      .mutateAsync()
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        const _fileName = getFileNameFromHeader(contentDisposition) ?? fileName;
        // Create a Blob from the response data and generate a download URL
        const blob = new Blob([response.data as Blob], { type: fileType });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = _fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
        notification.success(successMessage);
      })
      .catch((resError) => {
        notification.danger(
          errorMessage ?? t(`NETWORK_ERRORS.${resError.errors[0]}`)
        );
      });
  };

  return (
    <Button compact variant="outlined" onClick={handleExport}>
      {buttonLabel}
    </Button>
  );
};

export default ExportButton;
