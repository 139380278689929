import {
  Button,
  Tag,
  useNotifications,
  type SimpleDate,
} from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { createPortal } from 'react-dom';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useCancellationDialog } from '../../../../hooks/useCancellationDialog';
import { useModal } from '../../../../hooks/useModal';
import { useAttachStudentsToFamily } from '../../../../hooks/users/students/useAttachStudentsToFamily';
import { useGetStudentById } from '../../../../hooks/users/students/useGetStudentById';
import { useUpdateStudentById } from '../../../../hooks/users/students/useUpdateStudentById';
import { useGetRelatives } from '../../../../hooks/users/useGetRelatives';
import type { CreateUserForm } from '../../../../models/users/create-user-form';
import { Scholarship } from '../../../../models/users/students/scholarship-type';
import type { StudentUpdateFormDto } from '../../../../models/users/students/student-update-form.dto';
import type { StudentUpdateDto } from '../../../../models/users/students/student-update.dto';
import { mapSimpleDateToString } from '../../../../services/mapSimpleDateToString';
import {
  Checkbox,
  Select,
  Textarea,
} from '../../../../utils/components/hoc-components';
import { CREATE_EDIT_USER } from '../../../../utils/constants/users/modals';
import { ROLE } from '../../../../utils/constants/users/role';
import { Role } from '../../../../utils/enums/role.enum';
import { Status } from '../../../../utils/enums/status.enum';
import { getTagTypeByRole } from '../../../../utils/getTagType';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../../utils/inputValidators';
import { isArchivedUser } from '../../../../utils/isArchivedUser';
import { QuestionsAnswers } from '../../../admission/application/Q&A';
import { ActionMenuHorizontal } from '../../../shared/components/ActionMenuHorizontal';
import { FileUpload } from '../../../shared/components/FileUpload';
import { FormSkeleton } from '../../../shared/components/skeletons/FormSkeleton';
import { TableSkeleton } from '../../../shared/components/skeletons/TableSkeleton';
import { FilesTable } from './common/FilesTable';
import RelationsTable from './common/RelationsTable';
import { StudentSection } from './common/StudentSection';

export function StudentForm({ footerElement }: CreateUserForm) {
  const { t } = useTranslation();
  const notification = useNotifications();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('userId');
  const status = searchParams.get('userStatus');
  const familyId = searchParams.get('familyId');
  const navigateBack = searchParams.get('navigateBack') ?? '';

  const { close: closeDialog, open: openCreateEditUserModal } =
    useModal(CREATE_EDIT_USER);
  const { open: openCancellationDialog } = useCancellationDialog();

  const { data: user, isLoading } = useGetStudentById(id, Boolean(id));

  const {
    data: relatives,
    isPending: isRelativesDataPending,
    isError: isRelativesDataError,
  } = useGetRelatives(id, Role.Student, false);

  const headers = [
    t('USERS.TABLE.HEADERS.NAME'),
    t('USERS.TABLE.HEADERS.ACTION'),
  ];

  const tableChidren = useMemo(() => {
    if (relatives) {
      const parentsArrEnd = relatives.parents.length;
      const rows = relatives?.parents?.concat(relatives?.students);

      return rows.reduce((acc: any, user, index: number) => {
        const role = index >= parentsArrEnd ? Role.Student : Role.Parent;
        let userArray = [
          user.name,
          <span
            className="drawer__users__form__students__relations"
            key={user.id}
          >
            <Tag type={getTagTypeByRole(role)}>
              {t(`USERS.TABLE.ROLE.${ROLE[role]}`)}
            </Tag>
            <ActionMenuHorizontal
              options={[
                {
                  label: t('ACTIONS.VIEW_RELATIVE'),
                  onClick: () => {
                    openCreateEditUserModal({
                      userId: user.id,
                      userRole: role.toString(),
                      userStatus: Status.Active.toString(),
                      navigateBack: 'true',
                    });
                  },
                },
              ]}
            />
          </span>,
        ];
        return [...acc, userArray];
      }, []);
    }
  }, [relatives, t, openCreateEditUserModal]);

  const scholarshipOptions = Object.entries(Scholarship).map(
    ([key, value]) => ({
      label: t(`USERS.DRAWER.LABELS.${key}`),
      value,
    })
  );

  const methods = useForm<StudentUpdateFormDto>({
    defaultValues: {
      id: '',
      name: '',
      address: '',
      birthDate: '' as unknown as SimpleDate,
      allergies: '',
      siblings: '',
      medicalCondition: '',
      gpName: '',
      gpPhoneNumber: '',
      egn: '',
      birthplace: '',
      schoolYear: '',
      citizenship: '',
      files: [],
      notes: '',
      additionalInformation: Array.from({ length: 6 }, (_, i) => i + 1).map(
        (num) => ({
          question: t(`ADMISSION.APPLICATION.QUESTIONS.Q${num}`) as string,
          answer: '',
        })
      ),
    },
    values: user,
  });

  const { isDirty } = methods.formState;

  const { append: appendFileToTable, remove: removeFileFromTable } =
    useFieldArray({
      control: methods.control,
      name: 'files',
      keyName: 'files',
    });

  const files = methods.watch('files');

  const updateStudent = useUpdateStudentById();
  const attachStudentsToParent = useAttachStudentsToFamily(familyId);

  const onSubmit = async (data: StudentUpdateFormDto) => {
    const formData: StudentUpdateDto = {
      ...data,
      birthDate: mapSimpleDateToString(data.birthDate),
      fileIds: data.files?.map((file) => file.id),
      additionalInformation: Array.from({ length: 6 }, (_, i) => i + 1).map(
        (num) => ({
          question: t(`ADMISSION.APPLICATION.QUESTIONS.Q${num}`) as string,
          answer: data.additionalInformation![num - 1].answer,
        })
      ),
    };
    if (familyId) {
      attachStudentsToParent
        .mutateAsync({
          newStudents: [formData],
          existingStudents: [],
        })
        .then(() => {
          closeDialog(Boolean(navigateBack));
          notification.success(t('USERS.DRAWER.MESSAGES.ATTACH_COMPLETED'));
          queryClient.invalidateQueries({
            queryKey: ['parent-students'],
          });
        })
        .catch((resError) => {
          notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
        });
    } else {
      await updateStudent
        .mutateAsync(formData)
        .then(() => {
          closeDialog(Boolean(navigateBack));
          notification.success(t('USERS.DRAWER.MESSAGES.STUDENT_UPDATED'));
          queryClient.invalidateQueries({ queryKey: ['users'] });
          queryClient.invalidateQueries({ queryKey: ['student', id] });
          queryClient.invalidateQueries({
            queryKey: ['parent-students'],
          });
        })
        .catch((resError) => {
          notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
        });
    }
  };

  const onCloseModal = () => {
    if (isDirty) {
      openCancellationDialog();
    } else {
      closeDialog(Boolean(navigateBack));
    }
  };

  if (isLoading && id) {
    return <FormSkeleton />;
  }
  return (
    <div>
      {id && (
        <>
          <h3 className="drawer__users__form__students__title">
            {t(`USERS.DRAWER.TITLES.RELATIVES`)}
          </h3>
          {isRelativesDataPending ? (
            <TableSkeleton />
          ) : isRelativesDataError ? (
            t(`USERS.DRAWER.MESSAGES.NO_RELATIVES`)
          ) : (
            <RelationsTable
              tableLabel="RELATIVES"
              tableHeaders={headers}
              tableChildren={tableChidren}
              showAddMoreButton={false}
            />
          )}
        </>
      )}
      <h3 className="drawer__users__form__students__title">
        {t(`USERS.DRAWER.TITLES.STUDENT_INFORMATION`)}
      </h3>
      <FormProvider {...methods}>
        <form
          id="student-form"
          className="drawer__users__form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <StudentSection />
          <QuestionsAnswers />
          <div className="mb-6">
            <Select
              name="scholarshipType"
              options={scholarshipOptions}
              placeholder={
                t('USERS.DRAWER.PLACEHOLDERS.SCHOLARSHIP_TYPE') ?? ''
              }
              label={t('USERS.DRAWER.LABELS.SCHOLARSHIP_TYPE') ?? ''}
            />
          </div>
          <div className="mb-6 drawer__users__form__employee__permissions">
            <Checkbox
              name="isSecondChild"
              className="drawer__users__form__employee__permissions__item"
            >
              {t('USERS.DRAWER.LABELS.SECOND_CHILD')}
            </Checkbox>
            <Checkbox
              name="isStudyingInCurrentYear"
              className="drawer__users__form__employee__permissions__item"
            >
              {t('USERS.DRAWER.LABELS.IS_CURRENTLY_STUDYING')}
            </Checkbox>
            <Checkbox
              name="willStudyNextYear"
              className="drawer__users__form__employee__permissions__item"
            >
              {t('USERS.DRAWER.LABELS.WILL_STUDY_NEXT_YEAR')}
            </Checkbox>
          </div>
          {!familyId && (
            <div>
              <h3 className="my-8 drawer__users__form__students__title">
                {t('USERS.DRAWER.TITLES.DOCUMENTS')}
              </h3>
              <FilesTable data={files} removeFile={removeFileFromTable} />
              <FileUpload appendFile={appendFileToTable} />
              <Textarea
                rules={getCommonValidators([V.MAX_LENGTH])}
                name="notes"
                rows={8}
                label={t('USERS.DRAWER.LABELS.DOCUMENT_NOTES') ?? ''}
              />
            </div>
          )}
        </form>
        {footerElement &&
          createPortal(
            <>
              <Button
                onClick={onCloseModal}
                className="drawer__users__form__actions__cancel"
                variant="outlined"
              >
                {t('ACTIONS.CANCEL')}
              </Button>
              <Button
                form="student-form"
                type="submit"
                disabled={isArchivedUser(status)}
              >
                {familyId ? t('ACTIONS.ATTACH_STUDENT') : t('ACTIONS.UPDATE')}
              </Button>
            </>,
            footerElement
          )}
      </FormProvider>
    </div>
  );
}
