import { useQuery } from '@tanstack/react-query';
import type { UserBase } from '../../models/users/user-create.dto';
import apiRequest from '../../services/apiWrapper';
import { USERS_URL } from '../../utils/constants/urlConstants';
import { Role } from '../../utils/enums/role.enum';

interface UserRelatives {
  role: number;
  parents: UserBase[];
  students: UserBase[];
}

async function fetchRelatives(
  role: number,
  id: string,
  isArchived: boolean
): Promise<UserRelatives> {
  let action = '';
  if (role === Role.Parent) action = 'parents';
  else if (role === Role.Student) action = 'students';

  const response = await apiRequest.get<UserRelatives>(
    `${USERS_URL}/${action}/${id}/relatives?areArchived=${isArchived}`
  );
  return response.data;
}

export function useGetRelatives(
  userId: string | null,
  userRole: number,
  isArchived: boolean
) {
  return useQuery({
    queryKey: ['relatives', userId],
    queryFn: () => fetchRelatives(userRole, userId!, isArchived),

    enabled:
      !!userId && (userRole === Role.Parent || userRole === Role.Student),
    refetchOnMount: 'always',
  });
}
