import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../../../services/apiWrapper';
import { TRANSPORT_PAYMENTS } from '../../../../utils/constants/urlConstants';

async function fetchExportTransportationTaxesReport(
  searchParams: URLSearchParams
) {
  return await apiRequest.get(
    `${TRANSPORT_PAYMENTS}/export?${searchParams.toString()}`,
    { responseType: 'blob' }
  );
}

export function useExportTransportationTaxesReport(
  searchParams: URLSearchParams
) {
  return useMutation({
    mutationFn: () => fetchExportTransportationTaxesReport(searchParams),
  });
}
