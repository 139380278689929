import {
  UserMultiple,
  UserFilled,
  User,
  Settings,
  Purchase,
  RestaurantFine,
  Bus,
  Menu,
  Finance,
  Archive,
  Report,
  DataTable,
} from '@carbon/icons-react';
import { ActionMenu, SideMenu } from '@ph-react-ui/core';
import type { NavigationItemModel } from '@ph-react-ui/core';
import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import Logo from '../../../../assets/icons/logo-denis-diderot.svg';
import { useAuth } from '../../../../hooks/auth/useAuth';
import { useLogout } from '../../../../hooks/auth/useLogout';
import { useMediaQuery } from '../../../../hooks/common/useMediaQuery';
import { useCancellationDialog } from '../../../../hooks/useCancellationDialog';
import { Role } from '../../../../utils/enums/role.enum';
import { ScopeAccess } from '../../../../utils/enums/scope-access.enum';
import { CancellationDialog } from '../../components/CancellationDialog';
import { ModalFactory } from '../../components/ModalFactory';

export function DashboardLayout() {
  const { logout, authState } = useAuth();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const modal = searchParams.get('modal');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const logoutAction = useLogout();
  const isOnMobile = useMediaQuery('(max-width: 576px)');
  const [isCollapsed, setIsCollapsed] = useState(isOnMobile ? false : true);

  const { isOpen } = useCancellationDialog();

  const { month, year, currentDate } = useMemo(() => {
    const currentDate = new Date();
    return {
      month: currentDate.getMonth() + 1,
      year: currentDate.getFullYear(),
      currentDate,
    };
  }, []);

  const formattedCurrentDate = format(currentDate, 'yyyy-MM-dd');

  const { previousMonth, yearOfPrevMonth } = useMemo(() => {
    const isFirstMonth = month === 1;
    return {
      previousMonth: isFirstMonth ? 12 : month - 1,
      yearOfPrevMonth: isFirstMonth ? year - 1 : year,
    };
  }, [month, year]);

  useEffect(() => {
    if (isOnMobile) {
      setIsCollapsed(false);
    }
  }, [location, isOnMobile]);

  const adminSideMenu: NavigationItemModel[] = useMemo(() => {
    return [
      {
        label: t('SIDEMENU.USERS') ?? '',
        icon: <User className="dashboard__sidemenu__icon" />,
        path: '/dashboard/users',
        tooltip: t('SIDEMENU.USERS') ?? '',
        subMenuItems: [
          {
            label: t('SIDEMENU.ACTIVE'),
            handleNavigation: () =>
              navigate('/dashboard/users/active?status=active&page=1'),
            path: '/dashboard/users/active',
          },
          {
            label: t('SIDEMENU.ARCHIVED'),
            handleNavigation: () =>
              navigate(`/dashboard/users/archived?page=1&status=archived`),
            path: '/dashboard/users/archived',
          },
        ],
      },
      {
        label: t('SIDEMENU.GROUPS') ?? '',
        icon: <UserMultiple className="dashboard__sidemenu__icon" />,
        handleNavigation: () => navigate('/dashboard/groups'),
        path: '/dashboard/groups',
        tooltip: t('SIDEMENU.GROUPS') ?? '',
      },
      {
        label: t('SIDEMENU.FOOD_MANAGEMENT'),
        icon: <RestaurantFine className="dashboard__sidemenu__icon" />,
        handleNavigation: () =>
          navigate(`/dashboard/food-management?month=${month}&year=${year}`),
        path: '/dashboard/food-management',
        tooltip: t('SIDEMENU.FOOD_MANAGEMENT') ?? '',
      },
      {
        label: t('SIDEMENU.TRANSPORTATION_MANAGEMENT'),
        icon: <Bus className="dashboard__sidemenu__icon" />,
        path: '/dashboard/transportation',
        tooltip: t('SIDEMENU.TRANSPORTATION_MANAGEMENT') ?? '',
        subMenuItems: [
          {
            label: t('SIDEMENU.STUDENTS'),
            handleNavigation: () =>
              navigate(`/dashboard/transportation/students`),
            path: '/dashboard/transportation/students',
          },
          {
            label: t('SIDEMENU.ROUTES'),
            handleNavigation: () =>
              navigate(`/dashboard/transportation/routes`),
            path: '/dashboard/transportation/routes',
          },
        ],
      },
      {
        label: t('SIDEMENU.EVALUATION_PLANS'),
        icon: <Archive className="dashboard__sidemenu__icon" />,
        path: '/dashboard/evaluation-plan',
        tooltip: t('SIDEMENU.EVALUATION_PLANS') ?? '',
        subMenuItems: [
          {
            label: t('SIDEMENU.MY_EVALUATION_PLANS'),
            handleNavigation: () => navigate(`/dashboard/evaluation-plans/my`),
            path: '/dashboard/evaluation-plans/my',
          },
          {
            label: t('SIDEMENU.ALL_EVALUATION_PLANS'),
            handleNavigation: () => navigate(`/dashboard/evaluation-plans/all`),
            path: '/dashboard/evaluation-plans/all',
          },
          {
            label: t('SIDEMENU.EVALUATE_STUDENTS'),
            handleNavigation: () =>
              navigate(`/dashboard/evaluation-plans/students`),
            path: '/dashboard/evaluation-plans/students',
          },
        ],
      },
      {
        label: t('SIDEMENU.PAYMENTS_AND_TAXES'),
        icon: <Finance className="dashboard__sidemenu__icon" />,
        path: '/dashboard/taxes',
        tooltip: t('SIDEMENU.PAYMENTS_AND_TAXES') ?? '',
        subMenuItems: [
          {
            label: t('SIDEMENU.FOOD') ?? '',
            handleNavigation: () => navigate('/dashboard/taxes/food'),
            path: '/dashboard/taxes/food',
          },
          {
            label: t('SIDEMENU.TRANSPORTATION') ?? '',
            handleNavigation: () =>
              navigate(
                `/dashboard/taxes/transportation?month=${previousMonth}&year=${yearOfPrevMonth}`
              ),
            path: '/dashboard/taxes/transportation',
          },
          {
            label: t('SIDEMENU.SCHOOL_TAXES') ?? '',
            handleNavigation: () => navigate('/dashboard/taxes/school-taxes'),
            path: '/dashboard/taxes/school-taxes',
          },
          {
            label: t('SIDEMENU.MONTHLY_REPORTS') ?? '',
            handleNavigation: () =>
              navigate('/dashboard/taxes/monthly-reports'),
            path: '/dashboard/taxes/monthly-reports',
          },
        ],
      },
      {
        label: t('SIDEMENU.TERM_REPORTS') ?? '',
        icon: <Report className="dashboard__sidemenu__icon" />,
        handleNavigation: () => navigate('/dashboard/term-reports'),
        path: '/dashboard/term-reports',
        tooltip: t('SIDEMENU.TERM_REPORTS') ?? '',
        subMenuItems: [
          {
            label: t('SIDEMENU.MY_REPORTS'),
            handleNavigation: () =>
              navigate('/dashboard/term-reports/my-reports'),
            path: '/dashboard/term-reports/my-reports',
          },
          {
            label: t('SIDEMENU.STUDENT_REPORTS'),
            handleNavigation: () =>
              navigate('/dashboard/term-reports/student-reports'),
            path: '/dashboard/term-reports/student-reports',
          },
        ],
      },
      {
        label: t('SIDEMENU.STUDENT_ABSENCES') ?? '',
        icon: <DataTable className="dashboard__sidemenu__icon" />,
        handleNavigation: () =>
          navigate(
            `/dashboard/student-absences?fromDate=${formattedCurrentDate}&toDate=${formattedCurrentDate}`
          ),
        path: '/dashboard/student-absences',
        tooltip: t('SIDEMENU.STUDENT_ABSENCES') ?? '',
      },
      {
        label: t('SIDEMENU.SETTINGS') ?? '',
        icon: <Settings className="dashboard__sidemenu__icon" />,
        handleNavigation: () => navigate('/dashboard/settings'),
        path: '/dashboard/settings',
        tooltip: t('SIDEMENU.SETTINGS') ?? '',
      },
    ];
  }, [
    month,
    navigate,
    t,
    year,
    previousMonth,
    yearOfPrevMonth,
    formattedCurrentDate,
  ]);

  const parentSideMenu = useMemo(() => {
    return [
      {
        label: t('SIDEMENU.PROFILE') ?? '',
        icon: <User className="dashboard__sidemenu__icon" />,
        handleNavigation: () => navigate('/dashboard/profile'),
        path: '/dashboard/profile',
        tooltip: t('SIDEMENU.PROFILE'),
      },
      {
        label: t('SIDEMENU.FOOD_MANAGEMENT'),
        icon: <RestaurantFine className="dashboard__sidemenu__icon" />,
        handleNavigation: () => navigate('/dashboard/food-management'),
        path: '/dashboard/food-management',
        tooltip: t('SIDEMENU.FOOD_MANAGEMENT'),
      },
      {
        label: t('SIDEMENU.PAYMENTS') ?? '',
        icon: <Purchase className="dashboard__sidemenu__icon" />,
        handleNavigation: () => navigate('/dashboard/payments'),
        path: '/dashboard/payments',
        tooltip: t('SIDEMENU.PAYMENTS'),
      },
      {
        label: t('SIDEMENU.TRANSPORTATION') ?? '',
        icon: <Bus className="dashboard__sidemenu__icon" />,
        handleNavigation: () => navigate('/dashboard/transportation-plan'),
        path: '/dashboard/transportation-plan',
        tooltip: t('SIDEMENU.TRANSPORTATION'),
      },
      {
        label: t('SIDEMENU.TERM_REPORTS') ?? '',
        icon: <Report className="dashboard__sidemenu__icon" />,
        handleNavigation: () => navigate('/dashboard/term-reports'),
        path: '/dashboard/term-reports',
        tooltip: t('SIDEMENU.TERM_REPORTS'),
      },
    ];
  }, [navigate, t]);

  const employeeSideMenu: NavigationItemModel[] = useMemo(() => {
    const menuItems: NavigationItemModel[] = [
      {
        label: t('SIDEMENU.PROFILE') ?? '',
        icon: <User className="dashboard__sidemenu__icon" />,
        handleNavigation: () => navigate('/dashboard/profile'),
        path: '/dashboard/profile',
        tooltip: t('SIDEMENU.PROFILE') ?? '',
      },
    ];

    if (authState?.scope?.includes(ScopeAccess.StudentPlans)) {
      menuItems.push(
        {
          label: t('SIDEMENU.GROUPS') ?? '',
          icon: <UserMultiple className="dashboard__sidemenu__icon" />,
          handleNavigation: () => navigate('/dashboard/groups'),
          path: '/dashboard/groups',
          tooltip: t('SIDEMENU.GROUPS') ?? '',
        },
        {
          label: t('SIDEMENU.EVALUATION_PLANS'),
          icon: <Archive className="dashboard__sidemenu__icon" />,
          path: '/dashboard/evaluation-plan',
          tooltip: t('SIDEMENU.EVALUATION_PLANS') ?? '',
          subMenuItems: [
            {
              label: t('SIDEMENU.MY_EVALUATION_PLANS'),
              handleNavigation: () =>
                navigate(`/dashboard/evaluation-plans/my`),
              path: '/dashboard/evaluation-plans/my',
            },
            {
              label: t('SIDEMENU.ALL_EVALUATION_PLANS'),
              handleNavigation: () =>
                navigate(`/dashboard/evaluation-plans/all`),
              path: '/dashboard/evaluation-plans/all',
            },
            {
              label: t('SIDEMENU.EVALUATE_STUDENTS'),
              handleNavigation: () =>
                navigate(`/dashboard/evaluation-plans/students`),
              path: '/dashboard/evaluation-plans/students',
            },
          ],
        },
        {
          label: t('SIDEMENU.TERM_REPORTS') ?? '',
          icon: <Report className="dashboard__sidemenu__icon" />,
          handleNavigation: () => navigate('/dashboard/term-reports'),
          path: '/dashboard/term-reports',
          tooltip: t('SIDEMENU.TERM_REPORTS') ?? '',
          subMenuItems: [
            {
              label: t('SIDEMENU.MY_REPORTS'),
              handleNavigation: () =>
                navigate('/dashboard/term-reports/my-reports'),
              path: '/dashboard/term-reports/my-reports',
            },
            {
              label: t('SIDEMENU.STUDENT_REPORTS'),
              handleNavigation: () =>
                navigate('/dashboard/term-reports/student-reports'),
              path: '/dashboard/term-reports/student-reports',
            },
          ],
        },
        {
          label: t('SIDEMENU.STUDENT_ABSENCES') ?? '',
          icon: <DataTable className="dashboard__sidemenu__icon" />,
          handleNavigation: () =>
            navigate(
              `/dashboard/student-absences?fromDate=${formattedCurrentDate}&toDate=${formattedCurrentDate}`
            ),
          path: '/dashboard/student-absences',
          tooltip: t('SIDEMENU.STUDENT_ABSENCES') ?? '',
        }
      );
    }

    if (authState?.scope?.includes(ScopeAccess.Transport)) {
      menuItems.push(
        {
          label: t('SIDEMENU.TRANSPORTATION_MANAGEMENT'),
          icon: <Bus className="dashboard__sidemenu__icon" />,
          path: '/dashboard/transportation',
          tooltip: t('SIDEMENU.TRANSPORTATION_MANAGEMENT') ?? '',
          subMenuItems: [
            {
              label: t('SIDEMENU.STUDENTS'),
              handleNavigation: () =>
                navigate(`/dashboard/transportation/students`),
              path: '/dashboard/transportation/students',
            },
            {
              label: t('SIDEMENU.ROUTES'),
              handleNavigation: () =>
                navigate(`/dashboard/transportation/routes`),
              path: '/dashboard/transportation/routes',
            },
          ],
        },
        {
          label: t('SIDEMENU.PAYMENTS_AND_TAXES'),
          icon: <Finance className="dashboard__sidemenu__icon" />,
          handleNavigation: () =>
            navigate(
              `/dashboard/taxes/transportation?month=${previousMonth}&year=${yearOfPrevMonth}`
            ),
          path: '/dashboard/taxes/transportation',
          tooltip: t('SIDEMENU.PAYMENTS_AND_TAXES') ?? '',
        },
        {
          label: t('SIDEMENU.SETTINGS') ?? '',
          icon: <Settings className="dashboard__sidemenu__icon" />,
          handleNavigation: () => navigate('/dashboard/settings'),
          path: '/dashboard/settings',
          tooltip: t('SIDEMENU.SETTINGS') ?? '',
        }
      );
    }

    return menuItems;
  }, [
    authState,
    navigate,
    t,
    previousMonth,
    yearOfPrevMonth,
    formattedCurrentDate,
  ]);

  const sidemenuItems = useMemo(() => {
    switch (authState?.role) {
      case Role.Admin:
        return adminSideMenu;
      case Role.Parent:
        return parentSideMenu;
      case Role.Employee:
        return employeeSideMenu;
      default:
        return [];
    }
  }, [authState?.role, adminSideMenu, parentSideMenu, employeeSideMenu]);

  const handleLogout = async () => {
    await logoutAction.mutateAsync().finally(() => {
      logout();
      navigate('/');
    });
  };

  const goToMainPage = () => {
    if (authState?.role === Role.Admin) {
      navigate('/dashboard/users');
    } else {
      navigate('/dashboard/profile');
    }
  };

  return (
    <div
      className={`dashboard ${
        location.search.includes('modal') ? 'overflow-hidden' : ''
      }`}
    >
      <SideMenu
        logo={Logo}
        handleNavigation={goToMainPage}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        menuItems={sidemenuItems}
      />
      <div
        className={`dashboard__main ${isCollapsed ? '' : 'dashboard__large'}`}
      >
        <div className="dashboard__main__actions">
          <button
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="dashboard__menu__button"
          >
            <Menu className="dashboard__menu__button__icon" />
          </button>
          <ActionMenu
            position="left"
            customClasses="dashboard__main__usermenu"
            options={[
              {
                label: t('USERS.ACTIONS.LOGOUT') ?? '',
                onClick: handleLogout,
              },
            ]}
            toggleElement={
              <button className="dashboard__main__usermenu__toggle">
                <UserFilled className="users__actions__icons__item" />
              </button>
            }
          ></ActionMenu>
        </div>
        <Outlet />
        {modal &&
          createPortal(
            <ModalFactory modal={modal} />,
            document.getElementById('root')!
          )}
        {isOpen &&
          createPortal(
            <CancellationDialog />,
            document.getElementById('root')!
          )}
      </div>
    </div>
  );
}
