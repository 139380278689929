import { useNotifications } from '@ph-react-ui/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import apiRequest from '../../../services/apiWrapper';
import { STUDENTS_URL } from '../../../utils/constants/urlConstants';

async function approveStudentById(id: string) {
  return apiRequest.patch(`${STUDENTS_URL}/${id}/approve`, {});
}

export function useApproveStudentById() {
  const queryClient = useQueryClient();
  const notification = useNotifications();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (id: string) => approveStudentById(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      notification.success(t('USERS.MESSAGES.SUCCESS.APPROVE_STUDENT'));
    },
  });
}
