import { Status } from '../../enums/status.enum';

export const STATUS = {
  [Status.Active]: 'ACTIVE',
  [Status.Archived]: 'ARCHIVED',
  [Status.Invited]: 'INVITED',
  [Status.New]: 'NEW',
};

export const STATUSRev = {
  ACTIVE: Status.Active,
  ARCHIVED: Status.Archived,
  INVITED: Status.Invited,
  NEW: Status.New,
};
