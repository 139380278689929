import type { TagType } from '../models/common/tag.model';
import type { EvaluationPlanStatus } from './enums/evaluation-plan-status.enum';
import type { Role } from './enums/role.enum';
import type { Status } from './enums/status.enum';

export const getTagType = (status: Status | EvaluationPlanStatus): TagType => {
  const types: TagType[] = ['accent', 'pending', 'success', 'danger'];
  return types[status];
};

export const getTagTypeByRole = (role: Role): TagType => {
  const types: TagType[] = [
    'danger',
    'success',
    'accent',
    'pending',
    'pending',
  ];
  return types[role];
};
