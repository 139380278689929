import { Table } from '@ph-react-ui/core';
import type { ReactNode } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddStudentsToParentModal } from '../parents/AddStudentsToParentModal';
import AddBtn from './AddBtn';

interface RelationsTableProps {
  tableLabel: string;
  tableHeaders: (string | ReactNode)[];
  tableChildren: (string | number | ReactNode)[][];
  showAddMoreButton?: boolean;
}

const RelationsTable = ({
  tableLabel,
  tableHeaders,
  tableChildren,
  showAddMoreButton = true,
}: RelationsTableProps) => {
  const { t } = useTranslation();
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);

  return (
    <>
      <div className="drawer__users__form__students">
        {tableChildren && tableChildren.length > 0 ? (
          <Table headers={tableHeaders} rows={tableChildren} />
        ) : (
          t(`USERS.DRAWER.MESSAGES.NO_${tableLabel}`)
        )}
      </div>
      {showAddMoreButton && (
        <AddBtn
          btnLabel={t(
            `TRANSPORTATION_MANAGEMENT.DRAWER.TITLES.ADD_MORE_${tableLabel}`
          )}
          onClick={() => setIsAddUserModalOpen(true)}
        ></AddBtn>
      )}
      {isAddUserModalOpen && (
        <AddStudentsToParentModal setOpen={setIsAddUserModalOpen} />
      )}
    </>
  );
};

export default RelationsTable;
