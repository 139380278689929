import { DatePicker, Select } from '@ph-react-ui/core';
import type { SelectOption, SimpleDate } from '@ph-react-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import type { PathMatch } from 'react-router-dom';
import { useGetSchoolYearBaseSettings } from '../../../hooks/settings/useGetSchoolYearBaseSettings';
import { Scholarship } from '../../../models/users/students/scholarship-type';
import { mapStringToSimpleDate } from '../../../services/mapStringToSimpleDate';
import { PaymentMethodEnum } from '../../../utils/enums/payment-method.enum';
import { PaymentStatus } from '../../../utils/enums/payment-status.enum';
import { SearchInput } from './SearchByNameInput';

interface TaxesFiltersProps {
  pathMatch: PathMatch<string> | null;
  showPaymentPeriod?: boolean;
}

export const TaxesFilters = ({
  pathMatch,
  showPaymentPeriod = false,
}: TaxesFiltersProps) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const fromDateParam = searchParams.get('fromDate');
  const toDateParam = searchParams.get('toDate');
  const isPaid = searchParams.get('isPaid');
  const paymentMethodType = searchParams.get('paymentMethodType');
  const scholarshipType = searchParams.get('scholarshipType');
  const paymentPeriodParam = searchParams.get('paymentPeriod');

  const { data: schoolYearData } = useGetSchoolYearBaseSettings();

  const statusOptions: SelectOption<number | string>[] = [
    { label: t('TAXES.LABELS.ALL'), value: '' },
    { label: t('TAXES.LABELS.PAID'), value: PaymentStatus.Paid },
    { label: t('TAXES.LABELS.NOT_PAID'), value: PaymentStatus.NotPaid },
  ];

  const paymentMethodOptions: SelectOption<number | string>[] = [
    { label: t('TAXES.LABELS.ALL'), value: '' },
    { label: t('TAXES.LABELS.CARD'), value: PaymentMethodEnum.CARD },
    { label: t('TAXES.LABELS.TRANSFER'), value: PaymentMethodEnum.TRANSFER },
  ];

  const scholarshipTypeOptions: SelectOption<number | string>[] = [
    { label: t('TAXES.LABELS.ALL'), value: '' },
    {
      label: t('TAXES.LABELS.NO_SCHOLARSHIP'),
      value: Scholarship.NO_SCHOLARSHIP,
    },
    {
      label: t('TAXES.LABELS.FULL_SCHOLARSHIP'),
      value: Scholarship.FULL_SCHOLARSHIP,
    },
    {
      label: t('TAXES.LABELS.PARTIAL_SCHOLARSHIP'),
      value: Scholarship.PARTIAL_SCHOLARSHIP,
    },
  ];

  const onSetToDate = (value: SimpleDate | null) => {
    if (value) {
      const date = new Date(value.year, value.month, value.date);
      searchParams.set('toDate', format(date, 'yyyy-MM-dd'));
      searchParams.set('page', '1');
    } else {
      searchParams.delete('toDate');
    }
    setSearchParams(searchParams);
  };

  const onSetFromDate = (value: SimpleDate | null) => {
    if (value) {
      const date = new Date(value.year, value.month, value.date);
      searchParams.set('fromDate', format(date, 'yyyy-MM-dd'));
      searchParams.set('page', '1');
    } else {
      searchParams.delete('fromDate');
    }
    setSearchParams(searchParams);
  };

  const handleDropdownChange = (
    newVal: null | string | number | (string | number)[],
    searchParamKey: string,
    evalFunc?: (newVal: null | string | number | (string | number)[]) => string
  ) => {
    if (newVal === null) {
      return;
    }
    const value: string = evalFunc ? evalFunc(newVal) : (newVal as string);
    setSearchParamByKey(searchParamKey, value);
  };

  const setSearchParamByKey = (key: string, value: string) => {
    searchParams.set(key, value);
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };

  const onSetPaymentPeriod = (value: SimpleDate | null) => {
    if (value) {
      const date = new Date(value.year, value.month, 1);
      searchParams.set('fromPeriodDate', format(date, 'yyyy-MM-dd'));
      searchParams.set('toPeriodDate', format(date, 'yyyy-MM-dd'));
      searchParams.set('page', '1');
    } else {
      searchParams.delete('fromPeriodDate');
      searchParams.delete('toPeriodDate');
    }
    setSearchParams(searchParams);
  };

  return (
    <>
      <SearchInput
        pathMatch={pathMatch}
        placeholder={t('TAXES.FILTERS.SEARCH_BY_NAME')}
      />
      <div className="taxes__food__filter__dates">
        <DatePicker
          value={mapStringToSimpleDate(fromDateParam)}
          compact
          onChange={onSetFromDate}
          placeholder={t('TAXES.LABELS.FROM_DATE') ?? ''}
          maxDate={schoolYearData?.endOfSchoolYear}
          label={t('TAXES.LABELS.FROM_DATE') ?? ''}
        />
        <DatePicker
          value={mapStringToSimpleDate(toDateParam)}
          compact
          onChange={onSetToDate}
          placeholder={t('TAXES.LABELS.TO_DATE') ?? ''}
          maxDate={schoolYearData?.endOfSchoolYear}
          label={t('TAXES.LABELS.TO_DATE') ?? ''}
        />
      </div>

      <div className="taxes__food__filter__select">
        <Select
          placeholder={t('TAXES.FILTERS.STATUS') ?? ''}
          compact
          options={statusOptions}
          onChange={(status) =>
            handleDropdownChange(status, 'isPaid', (status) =>
              status === PaymentStatus.Paid
                ? 'true'
                : status === PaymentStatus.NotPaid
                ? 'false'
                : ''
            )
          }
          value={
            isPaid === 'true'
              ? PaymentStatus.Paid
              : isPaid === 'false'
              ? PaymentStatus.NotPaid
              : ''
          }
          label={t('TAXES.FILTERS.STATUS') ?? ''}
        />
      </div>
      <div className="taxes__food__filter__select">
        <Select
          placeholder={t('TAXES.FILTERS.PAYMENT_METHOD') ?? ''}
          compact
          options={paymentMethodOptions}
          onChange={(method) => {
            handleDropdownChange(method, 'paymentMethodType');
            //the backend returns non paid payments when the payment method is Transfer, so we need to set isPaid to true
            setSearchParamByKey('isPaid', 'true');
          }}
          value={paymentMethodType ? +paymentMethodType : ''}
          label={t('TAXES.FILTERS.PAYMENT_METHOD') ?? ''}
        />
      </div>
      <div className="taxes__food__filter__select">
        <Select
          placeholder={t('TAXES.FILTERS.EDUCATION_TYPE') ?? ''}
          compact
          options={scholarshipTypeOptions}
          onChange={(status) => handleDropdownChange(status, 'scholarshipType')}
          value={scholarshipType ? +scholarshipType : ''}
          label={t('TAXES.FILTERS.EDUCATION_TYPE') ?? ''}
        />
      </div>
      {showPaymentPeriod && (
        <div className="taxes__food__filter__dates">
          <DatePicker
            value={mapStringToSimpleDate(paymentPeriodParam)}
            compact
            onChange={onSetPaymentPeriod}
            placeholder={t('TAXES.FILTERS.PAYMENT_PERIOD') ?? ''}
            maxDate={schoolYearData?.endOfSchoolYear}
            label={t('TAXES.FILTERS.PAYMENT_PERIOD') ?? ''}
          />
        </div>
      )}
    </>
  );
};
