import { useMutation } from '@tanstack/react-query';
import apiRequest from '../../../../services/apiWrapper';
import { FOOD_PAYMENTS } from '../../../../utils/constants/urlConstants';

async function fetchExportFoodTaxesReport(searchParams: URLSearchParams) {
  return await apiRequest.get(
    `${FOOD_PAYMENTS}/export?${searchParams.toString()}`,
    { responseType: 'blob' }
  );
}

export function useExportFoodTaxesReport(searchParams: URLSearchParams) {
  return useMutation({
    mutationFn: () => fetchExportFoodTaxesReport(searchParams),
  });
}
