import { Search } from '@carbon/icons-react';
import { Button, Input } from '@ph-react-ui/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import type { PathMatch } from 'react-router-dom';

interface SearchInputProps {
  searchInputName?: string;
  pathMatch: PathMatch<string> | null;
  placeholder: string;
}

export const SearchInput = ({
  pathMatch,
  placeholder = '',
  searchInputName = 'name',
}: SearchInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchInput, setSearchInput] = useState(
    searchParams.get(searchInputName) ?? ''
  );

  const handleSearch = useCallback(() => {
    if (pathMatch) {
      if (searchInput) {
        const newParams = new URLSearchParams(searchParams.toString());
        newParams.set(searchInputName, searchInput);
        newParams.set('page', '1');
        setSearchParams(newParams);
      } else if (searchParams.get(searchInputName)) {
        const newParams = new URLSearchParams(searchParams.toString());
        newParams.delete(searchInputName);
        setSearchParams(newParams);
      }
    }
  }, [pathMatch, searchInput, searchParams, searchInputName, setSearchParams]);

  useEffect(() => {
    const inputElement = inputRef.current;
    if (!inputElement) return;

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleSearch();
      }
    };

    inputElement.addEventListener('keydown', handleKeyDown);

    // Cleanup listener on unmount
    return () => {
      inputElement.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSearch]);

  return (
    <div className="users__filters__actions__search">
      <Input
        ref={inputRef}
        value={searchInput}
        onChange={setSearchInput}
        compact
        placeholder={placeholder}
      />
      <Button
        className="users__filters__actions__form__btn"
        type="button"
        onClick={handleSearch}
      >
        <Search className="groups__actions__input__icon groups__actions__input__icon--action" />
      </Button>
    </div>
  );
};
