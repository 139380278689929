import { Folder } from '@carbon/icons-react';
import { Button, Checkbox, Table } from '@ph-react-ui/core';
import { format } from 'date-fns';
import { useMemo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePaginatedGroups } from '../../hooks/groups/usePaginatedGroups';
import { useModal } from '../../hooks/useModal';
import { type SelectAll, useSelectAll } from '../../hooks/useSelectAll';
import type { GroupsDto } from '../../models/groups/groups.dto';
import {
  CREATE_EDIT_GROUP,
  DELETE_GROUP,
  DELETE_MULTIPLE_GROUPS,
} from '../../utils/constants/groups/modals';
import { GroupOrdering } from '../../utils/enums/group-ordering.enum';
import { ActionMenuHorizontal } from '../shared/components/ActionMenuHorizontal';
import { Pagination } from '../shared/components/Pagination';
import { FilterSkeleton } from '../shared/components/skeletons/FilterSkeleton';
import { TableSkeleton } from '../shared/components/skeletons/TableSkeleton';
import { ThSortItem } from '../shared/components/tables/ThSortItem';
import { GroupsFilter } from '../users/filter/GroupsFilter';

export const Groups = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { open: openCreateEditGroupModal } = useModal(CREATE_EDIT_GROUP);
  const { open: openDeleteGroupModal } = useModal(DELETE_GROUP);
  const { open: openDeleteGroupsModal } = useModal(DELETE_MULTIPLE_GROUPS);

  const {
    data: groups,
    isPending,
    isSuccess,
  } = usePaginatedGroups(searchParams);

  const { handleSelectAll, handleSubmit, control, dirtyFields, selectAll } =
    useSelectAll(groups?.result);

  const openCreateGroupModal = () => {
    openCreateEditGroupModal();
  };

  const onOpenGroupEditModal = useCallback(
    (group: GroupsDto) => {
      openCreateEditGroupModal({
        groupId: group.id,
      });
    },
    [openCreateEditGroupModal]
  );

  const onOpenGroupDeleteModal = useCallback(
    (group: GroupsDto) => {
      openDeleteGroupModal({
        groupId: group.id,
      });
    },
    [openDeleteGroupModal]
  );

  const headers = [
    <div className="groups__table__select-all">
      <Checkbox
        onChange={(checked) => handleSelectAll(checked)}
        value={selectAll}
      />
      <ThSortItem
        title={t('GROUPS.TABLE.HEADERS.NAME')}
        ordering={GroupOrdering.Name}
      />
    </div>,
    <ThSortItem
      title={t('GROUPS.TABLE.HEADERS.CREATED_ON')}
      ordering={GroupOrdering.CreatedAt}
    />,
    ,
    t('USERS.TABLE.HEADERS.ACTION'),
  ];

  const tableChildren = useMemo(() => {
    return groups?.result.reduce((acc: any, group, index) => {
      let groupArray = [
        <div className="groups__table__checkbox">
          <Controller
            name={`items.${index}.value`}
            control={control}
            render={({ field }) => (
              <Checkbox className="groups__table-first" {...field} />
            )}
            disabled={group.isViewOnly}
          />
          <Folder size={20} />
          <span
            className="groups__table__checkbox__navigate"
            onClick={() =>
              navigate(`${group.id}/members`, { state: { group } })
            }
            aria-hidden
          >
            {group.name}
          </span>
        </div>,
        format(new Date(group.createdAt), 'dd.MM.yyyy'),
      ];

      if (!group.isViewOnly) {
        groupArray.push(
          <ActionMenuHorizontal
            options={[
              {
                label: t('ACTIONS.RENAME'),
                onClick: () => onOpenGroupEditModal(group),
              },
              {
                label: t('ACTIONS.DELETE'),
                onClick: () => onOpenGroupDeleteModal(group),
              },
            ]}
          />
        );
      }

      return [...acc, groupArray];
    }, []);
  }, [
    groups,
    t,
    control,
    navigate,
    onOpenGroupEditModal,
    onOpenGroupDeleteModal,
  ]);

  const onSubmit = (data: SelectAll) => {
    const selectedGroup = data.items.filter((group) => group.value);
    const ids = selectedGroup.map((item) => item.id).join('&');
    openDeleteGroupsModal({
      ids,
    });
  };

  return (
    <div className="groups">
      <section className="groups__actions">
        <Button compact variant="outlined" onClick={openCreateGroupModal}>
          {t('GROUPS.ACTIONS.CREATE_GROUP')}
        </Button>
      </section>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isPending ? (
          <FilterSkeleton />
        ) : (
          <GroupsFilter
            handleSubmit={handleSubmit(onSubmit)}
            isDisabled={!dirtyFields.items?.some((group) => group.value)}
          />
        )}
        {isSuccess && groups ? (
          <>
            <Table headers={headers} rows={tableChildren} />
            <div className="groups__pagination">
              <Pagination data={groups} />
            </div>
          </>
        ) : (
          <TableSkeleton headers={headers} />
        )}
      </form>
    </div>
  );
};
