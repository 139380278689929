import type { SelectOption, SimpleDate } from '@ph-react-ui/core';
import { Button, DatePicker, Select } from '@ph-react-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useMatch, useSearchParams } from 'react-router-dom';
import { useMediaQuery } from '../../../hooks/common/useMediaQuery';
import { useGetSchoolYearBaseSettings } from '../../../hooks/settings/useGetSchoolYearBaseSettings';
import { mapStringToSimpleDate } from '../../../services/mapStringToSimpleDate';
import { STATUS, STATUSRev } from '../../../utils/constants/users/status';
import { ModalType } from '../../../utils/enums/modal-type.enum';
import { Role } from '../../../utils/enums/role.enum';
import { Status } from '../../../utils/enums/status.enum';
import { SearchInput } from '../../shared/components/SearchByNameInput';

interface UserFilterProps {
  handleModalType: (modal: ModalType) => void;
  isDisabled: boolean;
}

export function UserFilter({ handleModalType, isDisabled }: UserFilterProps) {
  const { t } = useTranslation();
  const matchActive = useMatch('/dashboard/users/active');
  const matchArchived = useMatch('/dashboard/users/archived');
  const match = matchActive || matchArchived;

  const [searchParams, setSearchParams] = useSearchParams();
  const fromDateParam = searchParams.get('fromDate');
  const toDateParam = searchParams.get('toDate');
  const selectedStatus = searchParams.get('status');

  const { data: schoolYearData } = useGetSchoolYearBaseSettings();

  const statusOptions: SelectOption<number | string>[] = [
    { label: t('TAXES.LABELS.ALL'), value: '' },
    { label: t(`USERS.TABLE.STATUS.ACTIVE`), value: Status.Active },
    { label: t(`USERS.TABLE.STATUS.INVITED`), value: Status.Invited },
    { label: t(`USERS.TABLE.STATUS.NEW`), value: Status.New },
  ];

  const onSetToDate = (value: SimpleDate | null) => {
    if (value) {
      const date = new Date(value.year, value.month, value.date);
      searchParams.set('toDate', format(date, 'yyyy-MM-dd'));
      searchParams.set('page', '1');
    } else {
      searchParams.delete('toDate');
    }
    setSearchParams(searchParams);
  };

  const onSetFromDate = (value: SimpleDate | null) => {
    if (value) {
      const date = new Date(value.year, value.month, value.date);
      searchParams.set('fromDate', format(date, 'yyyy-MM-dd'));
      searchParams.set('page', '1');
    } else {
      searchParams.delete('fromDate');
    }
    setSearchParams(searchParams);
  };

  const handleStatusChange = (
    status: string | number | (string | number)[] | null
  ) => {
    if (status === null || Array.isArray(status)) {
      return;
    }

    const userStatus = Object.hasOwn(STATUS, status)
      ? STATUS[status as Status]
      : '';
    searchParams.set('status', userStatus.toLowerCase());
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };

  const getStatusValue = () => {
    return selectedStatus
      ? STATUSRev[selectedStatus.toUpperCase() as keyof typeof STATUSRev]
      : '';
  };

  return (
    <div className="users__filters">
      <UserFilterNavigation />
      <div className="users__filters__actions">
        <SearchInput
          pathMatch={match}
          placeholder={t('USERS.TABLE.FILTERS.SEARCH_USER')}
        />{' '}
        <div className="taxes__food__filter__dates">
          <DatePicker
            value={mapStringToSimpleDate(fromDateParam)}
            compact
            onChange={onSetFromDate}
            placeholder={t('USERS.TABLE.FILTERS.FROM_DATE') ?? ''}
            maxDate={schoolYearData?.endOfSchoolYear}
            label={t('USERS.TABLE.FILTERS.FROM_DATE') ?? ''}
          />
          <DatePicker
            value={mapStringToSimpleDate(toDateParam)}
            compact
            onChange={onSetToDate}
            placeholder={t('USERS.TABLE.FILTERS.TO_DATE') ?? ''}
            maxDate={schoolYearData?.endOfSchoolYear}
            label={t('USERS.TABLE.FILTERS.TO_DATE') ?? ''}
          />
        </div>
        {matchActive && (
          <div className="taxes__food__filter__select">
            <Select
              placeholder={t('TAXES.FILTERS.STATUS') ?? ''}
              compact
              options={statusOptions}
              onChange={(status) => handleStatusChange(status)}
              value={getStatusValue()}
              label={t('TAXES.FILTERS.STATUS') ?? ''}
            />
          </div>
        )}
        <Button
          type="button"
          onClick={() => handleModalType(ModalType.AddToGroup)}
          disabled={isDisabled}
        >
          {t('USERS.ACTIONS.ADD_TO_GROUP')}
        </Button>
        <Button
          type="button"
          onClick={() => handleModalType(ModalType.Archive)}
          disabled={isDisabled}
        >
          {t('USERS.ACTIONS.ARCHIVE')}
        </Button>
      </div>
    </div>
  );
}

export function UserFilterNavigation() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const role = searchParams.get('role') ?? '';
  const isOnMobile = useMediaQuery('(max-width: 768px)');
  const handleRoleChange = (
    role: string | number | (string | number)[] | null
  ) => {
    setSearchParams((prevParams) => {
      // Convert URLSearchParams to an object to spread
      const paramsObj = Object.fromEntries(prevParams.entries());
      // Convert role to string or empty string if null
      const roleStr = role !== null ? String(role) : '';
      return { ...paramsObj, role: roleStr, page: '1' };
    });
  };

  const userFilterOptions: SelectOption<string | number>[] = [
    {
      label: t('USERS.TABLE.FILTERS.ALL'),
      value: '',
    },
    {
      label: t('USERS.TABLE.FILTERS.STUDENTS'),
      value: Role.Student,
    },
    {
      label: t('USERS.TABLE.FILTERS.EMPLOYEES'),
      value: Role.Employee,
    },
    {
      label: t('USERS.TABLE.FILTERS.PARENTS'),
      value: Role.Parent,
    },
    {
      label: t('USERS.TABLE.FILTERS.CANDIDATES'),
      value: Role.Candidate,
    },
  ];

  if (isOnMobile) {
    return (
      <Select
        label={t('USERS.TABLE.HEADERS.ROLE') ?? ''}
        placeholder={t('USERS.TABLE.FILTERS.BY_ROLE') ?? ''}
        compact
        options={userFilterOptions}
        onChange={handleRoleChange}
      />
    );
  }
  return (
    <div className="users__filters__item-header">
      <div>
        <span className="users__filters__item__title">
          {t('USERS.TABLE.FILTERS.TITLE')}
        </span>
      </div>
      <button
        type="button"
        className={`users__filters__item ${
          role === '' && ' users__filters__item-active'
        }`}
        onClick={() => handleRoleChange('')}
      >
        {t('USERS.TABLE.FILTERS.ALL')}
      </button>
      <button
        type="button"
        className={`users__filters__item ${
          +role === Role.Student && ' users__filters__item-active'
        }`}
        onClick={() => handleRoleChange(Role.Student)}
      >
        {t('USERS.TABLE.FILTERS.STUDENTS')}
      </button>
      <button
        type="button"
        className={`users__filters__item ${
          +role === Role.Employee && ' users__filters__item-active'
        }`}
        onClick={() => handleRoleChange(Role.Employee)}
      >
        {t('USERS.TABLE.FILTERS.EMPLOYEES')}
      </button>
      <button
        type="button"
        className={`users__filters__item ${
          +role === Role.Parent && ' users__filters__item-active'
        }`}
        onClick={() => handleRoleChange(Role.Parent)}
      >
        {t('USERS.TABLE.FILTERS.PARENTS')}
      </button>
      <button
        type="button"
        className={`users__filters__item ${
          +role === Role.Candidate && ' users__filters__item-active'
        }`}
        onClick={() => handleRoleChange(Role.Candidate)}
      >
        {t('USERS.TABLE.FILTERS.CANDIDATES')}
      </button>
    </div>
  );
}
