import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { createPortal } from 'react-dom';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useCompleteRegistrationForBothParents } from '../../../../../hooks/admission/parent/useCompleteRegistrationForBothParents';
import { useCancellationDialog } from '../../../../../hooks/useCancellationDialog';
import { useModal } from '../../../../../hooks/useModal';
import { useGetStudentsForFamily } from '../../../../../hooks/users/family/useGetStudentsForFamily';
import { useGetParentById } from '../../../../../hooks/users/parents/useGetParentById';
import { useUpdateParentById } from '../../../../../hooks/users/parents/useUpdateParentById';
import { useInvitationById } from '../../../../../hooks/users/useInvitationById';
import type { CreateUserForm } from '../../../../../models/users/create-user-form';
import type {
  ParentsUpdateDto,
  ParentsWithUserProfileIdUpdateFormDto,
} from '../../../../../models/users/parents/parent-update.dto';
import type { AttachStudentsToParentFormDto } from '../../../../../models/users/students/attach-students-to-parent-form.dto';
import type { StudentUpdateFormWithIdDto } from '../../../../../models/users/students/student-update-form.dto';
import {
  Input,
  Textarea,
} from '../../../../../utils/components/hoc-components';
import { CREATE_EDIT_USER } from '../../../../../utils/constants/users/modals';
import { ParentSectionType } from '../../../../../utils/enums/parent-type.enum';
import { Role } from '../../../../../utils/enums/role.enum';
import { Status } from '../../../../../utils/enums/status.enum';
import { formatIbanNumber } from '../../../../../utils/formatIbanNumber';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../../../utils/inputValidators';
import { isActiveUser } from '../../../../../utils/isActiveUser';
import { isArchivedUser } from '../../../../../utils/isArchivedUser';
import { isInvitedUser } from '../../../../../utils/isInvitedUser';
import { isNewUser } from '../../../../../utils/isNewUser';
import { ActionMenuHorizontal } from '../../../../shared/components/ActionMenuHorizontal';
import { FileUpload } from '../../../../shared/components/FileUpload';
import { FormSkeleton } from '../../../../shared/components/skeletons/FormSkeleton';
import { FilesTable } from '../common/FilesTable';
import { ParentInvoicingSection } from '../common/ParentInvoicingSection';
import { ParentSection } from '../common/ParentSection';
import RelationsTable from '../common/RelationsTable';

export function UpdateParentForm({ footerElement }: CreateUserForm) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { open: openCancellationDialog } = useCancellationDialog();
  const notification = useNotifications();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('userId');

  const status = searchParams.get('userStatus');

  const { close: closeModal, open: openCreateEditUserModal } =
    useModal(CREATE_EDIT_USER);

  const { data: parent, isLoading: isLoadingParent } = useGetParentById(
    id,
    Boolean(id)
  );

  const { data: students, isLoading: isLoadingStudents } =
    useGetStudentsForFamily({
      parentId: parent?.familyInformation.id!,
      enabled: !!parent?.familyInformation.id,
    });

  const updateParent = useUpdateParentById(parent?.familyInformation.id!);
  const sendInvitation = useInvitationById();
  const sendInvitationToBothParents = useCompleteRegistrationForBothParents(
    parent?.familyInformation.id!
  );

  const parentMethods = useForm<ParentsWithUserProfileIdUpdateFormDto>({
    values: parent,
    defaultValues: {
      parents: [
        {
          userProfileId: '',
          name: '',
          phoneNumber: '',
          workAddress: '',
          email: '',
          address: '',
          isPrimaryParent: false,
          parentType: 0,
        },
        {
          userProfileId: '',
          name: '',
          phoneNumber: '',
          workAddress: '',
          email: '',
          address: '',
          isPrimaryParent: false,
          parentType: 1,
        },
      ],
      familyInformation: {
        invoiceInformation: {
          shouldReceiveInvoice: false,
          invoiceName: '',
          invoiceCity: '',
          invoiceStreet: '',
          invoiceZip: '',
          invoiceVatNo: '',
          invoiceIdNoBulstat: '',
        },
        files: [],
        notes: '',
        id: '',
      },
    },
  });

  const { isDirty: isDirtyParentForm } = parentMethods.formState;
  const receiveInvoice = parentMethods.watch(
    'familyInformation.invoiceInformation.shouldReceiveInvoice'
  );

  const studentsMethods = useForm<{
    students: StudentUpdateFormWithIdDto[] | undefined;
  }>({
    values: { students },
  });

  const { isDirty: isDirtyStudentsForm } = studentsMethods.formState;

  const attachStudentMethods = useForm<{
    students: AttachStudentsToParentFormDto;
  }>({
    defaultValues: {
      students: {
        existingStudents: [],
        newStudents: [],
      },
    },
  });

  const { isDirty: isDirtyAttachingStudentForm } =
    attachStudentMethods.formState;

  const headers = [
    t('USERS.TABLE.HEADERS.NAME'),
    t('USERS.TABLE.HEADERS.ACTION'),
  ];

  const tableChildren = useMemo(() => {
    return students?.reduce((acc: any, student) => {
      let studentArray = [
        student.name,
        <ActionMenuHorizontal
          options={[
            {
              label: t('ACTIONS.VIEW_STUDENT'),
              onClick: () => {
                openCreateEditUserModal({
                  userId: student.id,
                  userRole: Role.Student.toString(),
                  userStatus: Status.Active.toString(),
                  navigateBack: 'true',
                });
              },
            },
          ]}
        />,
      ];
      return [...acc, studentArray];
    }, []);
  }, [students, t, openCreateEditUserModal]);

  const { append: appendFileToTable, remove: removeFileFromTable } =
    useFieldArray({
      control: parentMethods.control,
      name: 'familyInformation.files',
      keyName: 'familyInformation.files',
    });

  const files = parentMethods.watch('familyInformation.files');
  const parent1AsMainWatch = parentMethods.watch('parents.0.isPrimaryParent');
  const parent2AsMainWatch = parentMethods.watch('parents.1.isPrimaryParent');

  const onUpdateParent = async (
    data: ParentsWithUserProfileIdUpdateFormDto
  ) => {
    const { familyInformation, ...rest } = data;
    const formData: ParentsUpdateDto = {
      ...rest,
      familyInformation: {
        id: familyInformation.id,
        notes: familyInformation.notes,
        iban: formatIbanNumber(familyInformation.iban),
        invoiceInformation: familyInformation.invoiceInformation,
        fileIds: familyInformation.files.map((file) => file.id),
      },
    };
    await updateParent
      .mutateAsync(formData)
      .then(() => {
        notification.success(t('USERS.DRAWER.MESSAGES.PARENT_UPDATED'));
        queryClient.invalidateQueries({ queryKey: ['parent', id] });
        queryClient.invalidateQueries({ queryKey: ['users'] });
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
        parentMethods.reset();
      });
  };

  const onSendInvitation = () => {
    if (parent1AsMainWatch || parent2AsMainWatch) {
      sendInvitation
        .mutateAsync(parent?.parents[parent1AsMainWatch ? 0 : 1].id!)
        .then(() => {
          notification.success(t('USERS.DRAWER.MESSAGES.INVITATION_SEND'));
          queryClient.invalidateQueries({ queryKey: ['users'] });
          onCloseModal();
        })
        .catch((resError) => {
          notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
        });
    }
  };

  const onSendInvitationToBothParents = () => {
    sendInvitationToBothParents
      .mutateAsync()
      .then(() => {
        notification.success(t('USERS.DRAWER.MESSAGES.INVITATION_SEND'));
        queryClient.invalidateQueries({ queryKey: ['users'] });
        onCloseModal();
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const onCloseDrawer = () => {
    if (
      !isArchivedUser(status) &&
      (isDirtyParentForm || isDirtyStudentsForm || isDirtyAttachingStudentForm)
    ) {
      openCancellationDialog();
    } else {
      closeModal();
    }
  };

  const onCloseModal = () => {
    closeModal();
  };

  const allowInvitingGuardian = () => {
    //if both parent are active
    if (
      isActiveUser(parent?.parents[0].status!) &&
      isActiveUser(parent?.parents[1].status!)
    ) {
      //allow sending only if we have made some changes and one of the parents is primary
      return (
        updateParent.isSuccess &&
        (parent?.parents[0].isPrimaryParent ||
          parent?.parents[1].isPrimaryParent ||
          parent1AsMainWatch ||
          parent2AsMainWatch)
      );
    } else {
      //if one or both of the parents are not active
      return (
        (parent1AsMainWatch || parent2AsMainWatch) &&
        (updateParent.isSuccess ||
          parent?.parents[0].isPrimaryParent ||
          parent?.parents[1].isPrimaryParent)
      );
    }
  };

  const allowInvitingBoth = () => {
    // enable resending of the invite
    if (isInvitedUser(status)) {
      return true;
    }
    // if one of the users is not actice => sending invite enabled
    if (
      !isActiveUser(parent?.parents[0].status!) ||
      !isActiveUser(parent?.parents[1].status!)
    ) {
      return true;
    } else {
      // if both users are active => sending invite disabled unless we have made some changes
      return updateParent.isSuccess;
    }
  };

  if (isLoadingParent || isLoadingStudents) {
    return <FormSkeleton />;
  }
  return (
    <div>
      <h3 className="drawer__users__form__students__title">
        {t(`USERS.DRAWER.TITLES.STUDENTS`)}
      </h3>
      <RelationsTable
        tableLabel="STUDENTS"
        tableHeaders={headers}
        tableChildren={tableChildren}
      />
      <FormProvider {...parentMethods}>
        <form
          id="parent-form"
          className="drawer__users__form"
          onSubmit={parentMethods.handleSubmit((data) => onUpdateParent(data))}
        >
          <h3 className="my-3 drawer__users__form__students__title">
            {t('USERS.DRAWER.TITLES.PARENTS_INFORMATIONS')}
          </h3>
          <h4 className="mt-3 drawer__users__form__students__subtitle">
            {t('USERS.DRAWER.TITLES.MOTHER_INFO')}
          </h4>
          <ParentSection
            prefix={'parents[0].'}
            mainParent={parent1AsMainWatch}
            setMainParent={() => {
              parentMethods.setValue(
                'parents.0.isPrimaryParent',
                !parent1AsMainWatch
              );
              if (parent2AsMainWatch && !parent1AsMainWatch) {
                parentMethods.setValue('parents.1.isPrimaryParent', false);
              }
            }}
            type={ParentSectionType.MOTHER}
          />
          <hr />
          <h4 className="drawer__users__form__students__subtitle">
            {t('USERS.DRAWER.TITLES.FATHER_INFO')}
          </h4>
          <ParentSection
            prefix={'parents[1].'}
            mainParent={parent2AsMainWatch}
            setMainParent={() => {
              parentMethods.setValue(
                'parents.1.isPrimaryParent',
                !parent2AsMainWatch
              );
              if (parent1AsMainWatch && !parent2AsMainWatch) {
                parentMethods.setValue('parents.0.isPrimaryParent', false);
              }
            }}
            type={ParentSectionType.FATHER}
          />
          <div className="my-3">
            <ParentInvoicingSection
              shouldReceiveInvoiceWatch={receiveInvoice}
              setReceiveInvoice={() =>
                parentMethods.setValue(
                  'familyInformation.invoiceInformation.shouldReceiveInvoice',
                  !receiveInvoice
                )
              }
              prefix="familyInformation."
            />
          </div>
          <div className="my-3">
            <Input
              name="familyInformation.iban"
              rules={getCommonValidators([V.IBAN_PATTERN])}
              className="drawer__users__form__input"
              placeholder={t('USERS.DRAWER.PLACEHOLDERS.IBAN') ?? ''}
              label={t('USERS.DRAWER.LABELS.IBAN') ?? ''}
            />
          </div>
          <div>
            <h3 className="my-8 drawer__users__form__students__title">
              {t('USERS.DRAWER.TITLES.DOCUMENTS')}
            </h3>
            <FilesTable data={files} removeFile={removeFileFromTable} />
            <FileUpload appendFile={appendFileToTable} />
            <Textarea
              name="familyInformation.notes"
              rules={getCommonValidators([V.MAX_LENGTH])}
              rows={8}
              label={t('USERS.DRAWER.LABELS.DOCUMENT_NOTES') ?? ''}
            />
          </div>
        </form>
        {footerElement &&
          createPortal(
            <>
              <Button
                onClick={onCloseDrawer}
                className="drawer__users__form__actions__cancel"
                variant="outlined"
              >
                {t('ACTIONS.CANCEL')}
              </Button>
              <Button
                form="parent-form"
                disabled={isArchivedUser(status)}
                onClick={parentMethods.handleSubmit((data) =>
                  onUpdateParent(data)
                )}
              >
                {t('ACTIONS.UPDATE')}
              </Button>
              <Button
                onClick={onSendInvitation}
                disabled={!allowInvitingGuardian()}
              >
                {isNewUser(status)
                  ? t('ACTIONS.SEND_INVITATION')
                  : t('ACTIONS.SEND_INVITATION_AGAIN')}
              </Button>
              <Button
                onClick={onSendInvitationToBothParents}
                disabled={!allowInvitingBoth()}
              >
                {isNewUser(status)
                  ? t('ACTIONS.SEND_INVITATION_BOTH')
                  : t('ACTIONS.SEND_INVITATION_AGAIN_BOTH')}
              </Button>
            </>,
            footerElement
          )}
      </FormProvider>
    </div>
  );
}
