import { Button, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useDeleteGroupsById } from '../../../hooks/groups/useDeleteGroups';
import { useModal } from '../../../hooks/useModal';
import { DELETE_MULTIPLE_GROUPS } from '../../../utils/constants/groups/modals';
import { Dialog } from '../../shared/components/Dialog';

export const DeleteMultipleGroups = () => {
  const { t } = useTranslation();
  const notification = useNotifications();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const ids = searchParams.get('ids')?.split('&') as [];
  const { close: closeModal } = useModal(DELETE_MULTIPLE_GROUPS);

  const deleteGroups = useDeleteGroupsById();

  const handleDelete = async () => {
    deleteGroups
      .mutateAsync(ids)
      .then(() => {
        notification.success(
          t('GROUPS.DIALOG.MESSAGES.DELETE_MULTIPLE_SUCCESS')
        );
        queryClient.invalidateQueries({ queryKey: ['groups'] });
        closeModal();
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  return (
    <Dialog
      onClose={() => closeModal()}
      dialogHeader={t('GROUPS.DIALOG.HEADERS.DELETE_GROUPS')}
      actionButton={
        <Button onClick={handleDelete}>{t('ACTIONS.DELETE')}</Button>
      }
    >
      <h2>{t('GROUPS.DIALOG.CONTENT.DELETE_GROUPS_ARE_YOU_SURE')}</h2>
      <h4>{t('GROUPS.DIALOG.CONTENT.DELETE_WARNING')}</h4>
    </Dialog>
  );
};
