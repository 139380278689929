import { useQuery } from '@tanstack/react-query';
import type { PaginatedList } from '../../../../models/common/paginated-list.model';
import type { AdminPromisedSchoolTaxesDTO } from '../../../../models/taxes/school/school-taxes-details.dto';
import apiRequest from '../../../../services/apiWrapper';
import { createPaginatedQueryKeys } from '../../../../services/createPaginatedQueryKeys';
import { SCHOOL_TAX_PAYMENTS } from '../../../../utils/constants/urlConstants';

async function getPromisedSchoolTaxes(searchParams: URLSearchParams) {
  const response = await apiRequest.get<
    PaginatedList<AdminPromisedSchoolTaxesDTO>
  >(`${SCHOOL_TAX_PAYMENTS}/students?${searchParams.toString()}`);

  return response.data;
}

export function createQueryKey(searchParams: URLSearchParams) {
  return {
    fromDate: searchParams.get('fromDate'),
    toDate: searchParams.get('toDate'),
    name: searchParams.get('name'),
    isPaid: searchParams.get('isPaid'),
    paymentMethodType: searchParams.get('paymentMethodType'),
    scholarshipType: searchParams.get('scholarshipType'),
    fromPeriodDate: searchParams.get('fromPeriodDate'),
    toPeriodDate: searchParams.get('toPeriodDate'),
    ...createPaginatedQueryKeys(searchParams),
  };
}

export function usePaginatedPromisedSchoolTaxes(searchParams: URLSearchParams) {
  return useQuery({
    queryKey: ['promised-school-taxes', createQueryKey(searchParams)],
    queryFn: () => getPromisedSchoolTaxes(searchParams),
    throwOnError: false,
  });
}
