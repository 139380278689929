import { UserMultiple } from '@carbon/icons-react';
import { Button, Checkbox, Table, Tag } from '@ph-react-ui/core';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useMatch,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { usePaginatedGroupMembers } from '../../hooks/groups/members/usePaginatedGroupMembers';
import { useGetGroupById } from '../../hooks/groups/useGetGroupById';
import { useModal } from '../../hooks/useModal';
import { type SelectAll, useSelectAll } from '../../hooks/useSelectAll';
import type { GroupMemberDetailsDto } from '../../models/groups/group-member-details.dto';
import { MOVE_GROUP_MEMBER } from '../../utils/constants/groups/modals';
import {
  ADD_GROUP_USER,
  ADD_TO_GROUP,
  REMOVE_GROUP_MEMBER,
} from '../../utils/constants/users/modals';
import { ROLE } from '../../utils/constants/users/role';
import { STATUS } from '../../utils/constants/users/status';
import { ModalType } from '../../utils/enums/modal-type.enum';
import { UserOrdering } from '../../utils/enums/user-ordering.enum';
import { getTagType } from '../../utils/getTagType';
import { ActionMenuHorizontal } from '../shared/components/ActionMenuHorizontal';
import { Pagination } from '../shared/components/Pagination';
import { SearchInput } from '../shared/components/SearchByNameInput';
import { FilterSkeleton } from '../shared/components/skeletons/FilterSkeleton';
import { TableSkeleton } from '../shared/components/skeletons/TableSkeleton';
import { ThSortItem } from '../shared/components/tables/ThSortItem';
import { GroupFilter } from '../users/filter/GroupFilter';

export const GroupMembers = () => {
  const { id } = useParams();
  const location = useLocation();
  const cachedGroup = location.state?.group; //Group data passed by Groups component
  const { t } = useTranslation();
  const match = useMatch('/dashboard/groups/:id/members');
  const [searchParams] = useSearchParams();

  const {
    data: members,
    isSuccess,
    isPending,
  } = usePaginatedGroupMembers(id ?? '', searchParams);

  const { data: responseGroup, isPending: isGroupPending } = useGetGroupById(
    id ?? '',
    !cachedGroup //don't fetch if cachedGroup is available
  );
  const group = cachedGroup || responseGroup;
  const isViewOnly =
    group?.isViewOnly || (!cachedGroup ? isGroupPending : false); //while is loading, set it to true to prevent content shifting

  const { open: openGroupModal } = useModal(ADD_TO_GROUP);
  const { open: openMoveMemberModal } = useModal(MOVE_GROUP_MEMBER);
  const { open: openNewGroupUserModal } = useModal(ADD_GROUP_USER);
  const { open: openRemoveMemberModal } = useModal(REMOVE_GROUP_MEMBER);

  const { control, handleSubmit, selectAll, handleSelectAll, dirtyFields } =
    useSelectAll(members?.result);

  const headers = [
    <div className="groups__table__select-all">
      {!isViewOnly && (
        <Checkbox
          onChange={(checked) => handleSelectAll(checked)}
          value={selectAll}
        />
      )}
      <ThSortItem
        title={t('USERS.TABLE.HEADERS.NAME')}
        ordering={UserOrdering.Name}
      />
    </div>,
    t('USERS.TABLE.HEADERS.ROLE'),
    t('USERS.TABLE.HEADERS.STATUS'),
    <ThSortItem
      title={t('USERS.TABLE.HEADERS.DATE_OF_REGISTRATION')}
      ordering={UserOrdering.CreatedAt}
    />,
  ];
  if (!isViewOnly) {
    headers.push(t('USERS.TABLE.HEADERS.ACTION') ?? '');
  }

  const onOpenGroupsModal = useCallback(
    (user: GroupMemberDetailsDto) => {
      openGroupModal({
        userId: user.id,
      });
    },
    [openGroupModal]
  );

  const onOpenRemoveMemberModal = useCallback(
    (user: GroupMemberDetailsDto) => {
      openRemoveMemberModal({
        userId: user.id,
      });
    },
    [openRemoveMemberModal]
  );

  const tableChildren = useMemo(() => {
    return members?.result.reduce((acc: any, member, index) => {
      let memberArray = [
        <div className="groups__table__checkbox">
          {!isViewOnly && (
            <Controller
              name={`items.${index}.value`}
              control={control}
              render={({ field }) => (
                <Checkbox className="groups__table-first" {...field} />
              )}
            />
          )}
          {member.name}
        </div>,
        t(`USERS.TABLE.ROLE.${ROLE[member.role]}`),
        <Tag type={getTagType(member.status)}>
          {t(`USERS.TABLE.STATUS.${STATUS[member.status]}`)}
        </Tag>,
        format(new Date(member.createdAt), 'dd.MM.yyyy'),
      ];

      if (!isViewOnly) {
        memberArray.push(
          <ActionMenuHorizontal
            options={[
              {
                label: t('ACTIONS.ADD_TO_GROUP'),
                onClick: () => onOpenGroupsModal(member),
              },
              {
                label: t('ACTIONS.REMOVE'),
                onClick: () => onOpenRemoveMemberModal(member),
              },
            ]}
          />
        );
      }

      return [...acc, memberArray];
    }, []);
  }, [
    t,
    members,
    isViewOnly,
    control,
    onOpenGroupsModal,
    onOpenRemoveMemberModal,
  ]);

  const onSubmit = (data: SelectAll, modal: ModalType) => {
    const selectedUsers = data.items.filter((member) => member.value);
    const ids = selectedUsers.map((item) => item.id).join('&');
    switch (modal) {
      case ModalType.AddToGroup:
        return openGroupModal({ userId: ids });
      case ModalType.MoveToGroup:
        return openMoveMemberModal({ userId: ids });
      case ModalType.RemoveGroupMember:
        return openRemoveMemberModal({ userId: ids });
      default:
    }
  };

  const handleModalType = (modal: ModalType) => {
    handleSubmit((data) => onSubmit(data, modal))();
  };

  return (
    <div className="groups">
      <section className="groups__actions">
        {!isViewOnly && (
          <Button
            compact
            variant="outlined"
            onClick={() => openNewGroupUserModal()}
          >
            {t('USERS.ACTIONS.ADD_A_USER')}
          </Button>
        )}
        <SearchInput
          pathMatch={match}
          placeholder={t('USERS.TABLE.FILTERS.SEARCH_USER')}
        />
      </section>
      <form>
        {isPending ? (
          <FilterSkeleton />
        ) : (
          <div className="groups__filters__container">
            <div className="users__filters__item-header">
              <UserMultiple size={24} />
              <span className="users__filters__item__title">{group?.name}</span>
            </div>
            {!isViewOnly && ( //If we don't have permission to edit the group, we don't show the filter
              <GroupFilter
                handleModalType={handleModalType}
                isDisabled={!dirtyFields.items?.some((user) => user.value)}
              />
            )}
          </div>
        )}
        {isSuccess && members ? (
          <>
            <Table headers={headers} rows={tableChildren} />
            <div className="groups__pagination">
              <Pagination data={members} />
            </div>
          </>
        ) : (
          <TableSkeleton headers={headers} />
        )}
      </form>
    </div>
  );
};
