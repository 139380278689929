import { Button, useNotifications } from '@ph-react-ui/core';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate, useParams } from 'react-router-dom';
import { useCompleteRegistrationForCandidate } from '../../../../hooks/admission/candidate/useCompleteRegistrationForCandidate';
import { useGetPublicCandidateById } from '../../../../hooks/admission/candidate/useGetPublicCandidateById';
import { useCompleteRegistrationForParent } from '../../../../hooks/admission/parent/useCompleteRegistrationForParent';
import { useGetPublicParentById } from '../../../../hooks/admission/parent/useGetPublicParentById';
import type {
  ParentsUpdateDto,
  ParentsWithUserProfileIdUpdateFormDto,
} from '../../../../models/users/parents/parent-update.dto';
import { Input } from '../../../../utils/components/hoc-components';
import { DocumentType } from '../../../../utils/enums/document-type.enum';
import { ParentSectionType } from '../../../../utils/enums/parent-type.enum';
import { Role } from '../../../../utils/enums/role.enum';
import { formatIbanNumber } from '../../../../utils/formatIbanNumber';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../../../utils/inputValidators';
import { FileListItem } from '../../../shared/components/FileListItem';
import { FileUpload } from '../../../shared/components/FileUpload';
import { MandatoryFileUpload } from '../../../shared/components/MandatoryFileUpload';
import { FormSkeleton } from '../../../shared/components/skeletons/FormSkeleton';
import { DialogDeleteFile } from '../../../users/create-edit/forms/common/DialogDeleteFile';
import { ParentInvoicingSection } from '../../../users/create-edit/forms/common/ParentInvoicingSection';
import { ParentSection } from '../../../users/create-edit/forms/common/ParentSection';
import { PasswordRequirements } from '../common/PasswordRequirements';
import { PasswordSection } from '../common/PasswordSection';

export interface CompleteParentCandidateProfileForm
  extends ParentsWithUserProfileIdUpdateFormDto {
  completeProfileData: {
    newPassword: string;
    confirmationPassword: string;
  };
}

export interface CompleteParentCandidateProfileFormDTO
  extends ParentsUpdateDto {
  completeProfileData: {
    newPassword: string;
    confirmationPassword: string;
  };
}

export const CompleteProfileParentCandidate = () => {
  const { t } = useTranslation();
  const notification = useNotifications();
  const { role } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const invitationId = searchParams.get('invitationId');
  const userId = searchParams.get('userId');
  const [deleteFileId, setDeleteFileIdModal] = useState('');

  const { data: parent, isLoading: isLoadingParent } = useGetPublicParentById(
    invitationId,
    Boolean(invitationId) && +role! === Role.Parent
  );
  const { data: candidate, isLoading: isLoadingCandidate } =
    useGetPublicCandidateById(
      invitationId,
      Boolean(invitationId) && +role! === Role.Candidate
    );

  const completeParentRegistration =
    useCompleteRegistrationForParent(invitationId);
  const completeCandidateRegistration =
    useCompleteRegistrationForCandidate(invitationId);

  const methods = useForm<CompleteParentCandidateProfileForm>({
    defaultValues: {
      parents: [
        {
          userProfileId: '',
          name: '',
          phoneNumber: '',
          workAddress: '',
          email: '',
          address: '',
          isPrimaryParent: false,
          parentType: 0,
        },
        {
          userProfileId: '',
          name: '',
          phoneNumber: '',
          workAddress: '',
          email: '',
          address: '',
          isPrimaryParent: false,
          parentType: 0,
        },
      ],
      familyInformation: {
        invoiceInformation: {
          shouldReceiveInvoice: false,
          invoiceName: '',
          invoiceCity: '',
          invoiceStreet: '',
          invoiceZip: '',
          invoiceVatNo: '',
          invoiceIdNoBulstat: '',
        },
        files: [],
        notes: '',
        id: '',
      },
      completeProfileData: {
        newPassword: '',
        confirmationPassword: '',
      },
    },
    values: {
      ...(parent || candidate)!,
      completeProfileData: {
        newPassword: '',
        confirmationPassword: '',
      },
    },
  });

  const password = methods.watch('completeProfileData.newPassword');
  const receiveInvoice = methods.watch(
    'familyInformation.invoiceInformation.shouldReceiveInvoice'
  );

  let showMotherPassword;
  let showFatherPassword;
  if (userId) {
    showMotherPassword =
      (parent?.parents[0].id ?? candidate?.parents[0].id) === userId;

    showFatherPassword =
      (parent?.parents[1].id ?? candidate?.parents[1].id) === userId;
  }
  if (!(showMotherPassword || showFatherPassword)) {
    showMotherPassword =
      parent?.parents[0].isPrimaryParent ||
      candidate?.parents[0].isPrimaryParent;
    showFatherPassword =
      parent?.parents[1].isPrimaryParent ||
      candidate?.parents[1].isPrimaryParent;
  }

  const {
    fields: fileFields,
    append: appendFileToList,
    remove: removeFileFromList,
  } = useFieldArray({
    control: methods.control,
    name: 'familyInformation.files',
    keyName: 'familyInformation.files',
  });

  const onSubmit = async (data: CompleteParentCandidateProfileForm) => {
    const completeRegistration =
      +role! === Role.Parent
        ? completeParentRegistration
        : completeCandidateRegistration;
    const { familyInformation, ...rest } = data;
    const formData: CompleteParentCandidateProfileFormDTO = {
      ...rest,
      familyInformation: {
        id: familyInformation.id,
        notes: familyInformation.notes,
        iban: formatIbanNumber(familyInformation.iban),
        invoiceInformation: familyInformation.invoiceInformation,
        fileIds: familyInformation.files.map((file) => file.id),
      },
    };
    await completeRegistration
      .mutateAsync(formData)
      .then(() => {
        notification.success(t('ADMISSION.COMPLETE_PROFILE.MESSAGES.SUCCESS'));
        setTimeout(() => {
          navigate('/');
        }, 3000);
      })
      .catch((resError) => {
        notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`));
      });
  };

  const nonRequiredFiles = useMemo(() => {
    return fileFields.filter((file) => file.shouldDisplay !== false);
  }, [fileFields]);

  const isAlreadyUploaded = useCallback(
    (type: DocumentType) => {
      return fileFields.some((file) => file.fileType === type);
    },
    [fileFields]
  );

  if (!invitationId) {
    navigate('/');
  }

  if (isLoadingCandidate && isLoadingParent) {
    return (
      <div className="admission">
        <FormSkeleton />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        id="complete-profile-form"
        className="admission__form"
        onSubmit={methods.handleSubmit((data: any) => onSubmit(data))}
        autoComplete="off"
      >
        <h3>{t('ADMISSION.COMPLETE_PROFILE.LABELS.PARENT_DETAILS')}</h3>
        <br />
        <h4 className="mt-3 drawer__users__form__students__subtitle">
          {t('USERS.DRAWER.TITLES.MOTHER_INFO')}
        </h4>
        <br />
        <ParentSection
          prefix={'parents[0].'}
          requiredFields={{
            phoneNumber: true,
            address: true,
            workAddress: true,
          }}
          hideMainParentToggle={true}
          type={ParentSectionType.MOTHER}
          inputDisabled={!showMotherPassword}
        />
        {showMotherPassword && <br />}
        {showMotherPassword && (
          <>
            <PasswordSection
              passwordWatcher={methods.watch('completeProfileData.newPassword')}
              prefix="completeProfileData."
            />

            <PasswordRequirements
              className="password__requirements-full"
              password={password}
            />
          </>
        )}
        {showFatherPassword && <br />}
        <h4 className="drawer__users__form__students__subtitle">
          {t('USERS.DRAWER.TITLES.FATHER_INFO')}
        </h4>
        <br />
        <ParentSection
          prefix={'parents[1].'}
          requiredFields={{
            phoneNumber: true,
            address: true,
            workAddress: true,
          }}
          hideMainParentToggle={true}
          type={ParentSectionType.FATHER}
          inputDisabled={!showFatherPassword}
        />
        {showFatherPassword && <br />}
        {showFatherPassword && (
          <>
            <PasswordSection
              passwordWatcher={methods.watch('completeProfileData.newPassword')}
              prefix="completeProfileData."
            />

            <PasswordRequirements
              className="password__requirements-full"
              password={password}
            />
          </>
        )}
        <ParentInvoicingSection
          shouldReceiveInvoiceWatch={receiveInvoice}
          setReceiveInvoice={() =>
            methods.setValue(
              'familyInformation.invoiceInformation.shouldReceiveInvoice',
              !receiveInvoice
            )
          }
          prefix="familyInformation."
        />
        <div className="my-3">
          <Input
            name="familyInformation.iban"
            rules={getCommonValidators([V.IBAN_PATTERN])}
            className="drawer__users__form__input"
            placeholder={t('USERS.DRAWER.PLACEHOLDERS.IBAN') ?? ''}
            label={t('USERS.DRAWER.LABELS.IBAN') ?? ''}
          />
        </div>
        {/* TODO: revisit */}
        {/* <h3>{t('ADMISSION.COMPLETE_PROFILE.LABELS.STUDENT_DETAILS')}</h3>
        <br /> */}
        {/* {(parent || candidate)?.students.map((_, index) => (
          <div className="mb-6" key={`student-${index}`}>
            <Input
              disabled
              name={`students.${index}.name`}
              className="drawer__users__form__input"
              placeholder={t('USERS.DRAWER.PLACEHOLDERS.NAME') ?? ''}
              label={t('USERS.DRAWER.LABELS.NAME')!}
            />
          </div>
        ))} */}
      </form>

      <div className="upload__file-documents">
        <div className="upload__file__required">
          <MandatoryFileUpload
            appendFile={appendFileToList}
            invitationId={invitationId!}
            title={t('USERS.DRAWER.DOCUMENTS.TYPE.BIRTH_CERTIFICATE')}
            fileType={DocumentType.BirthCertificate}
            isAlreadyAvailable={isAlreadyUploaded(
              DocumentType.BirthCertificate
            )}
          />
          <MandatoryFileUpload
            appendFile={appendFileToList}
            invitationId={invitationId!}
            title={t('USERS.DRAWER.DOCUMENTS.TYPE.MEDICAL_CONDITION')}
            fileType={DocumentType.MedicalCondition}
            isAlreadyAvailable={isAlreadyUploaded(
              DocumentType.MedicalCondition
            )}
          />
          <MandatoryFileUpload
            appendFile={appendFileToList}
            invitationId={invitationId!}
            title={t('USERS.DRAWER.DOCUMENTS.TYPE.RELOCATION')}
            fileType={DocumentType.Relocation}
            isAlreadyAvailable={isAlreadyUploaded(DocumentType.Relocation)}
          />
        </div>
        <FileUpload
          appendFile={appendFileToList}
          invitationId={invitationId!}
        />
        {nonRequiredFiles.length ? (
          <div className="upload__file__other">
            {nonRequiredFiles.map((file, index) => {
              return (
                file.shouldDisplay !== false && (
                  <FileListItem
                    key={index}
                    file={file}
                    index={index}
                    removeFile={() => setDeleteFileIdModal(file.id)}
                  />
                )
              );
            })}
          </div>
        ) : null}
        {deleteFileId && (
          <DialogDeleteFile
            fileId={deleteFileId}
            removeFile={removeFileFromList}
            closeModal={() => setDeleteFileIdModal('')}
            invitationId={invitationId!}
          />
        )}
      </div>
      <Button
        form="complete-profile-form"
        type="submit"
        disabled={
          completeCandidateRegistration.isPending ||
          completeParentRegistration.isPending
        }
        loading={
          completeCandidateRegistration.isPending ||
          completeParentRegistration.isPending
        }
      >
        {t('ACTIONS.CONTINUE')}
      </Button>
    </FormProvider>
  );
};
